import React, { useState } from "react";
import "./WhoAreWeSection.css";
import { ReactComponent as WhoAreWeOwlQuestion } from "../../assets/img/WhoAreWeOwlQuestion.svg";
import RoshaanImage from "../../assets/img/Roshaan.png";
import SyedImage from "../../assets/img/Syed.png";
import EbadurImage from "../../assets/img/Ebadur.png";
import HaseebImage from "../../assets/img/Haseeb.png";
import HaseebImage2 from "../../assets/img/Haseeb2.png";
import UmairImage from "../../assets/img/Umair.png";
import OwlLogoSvg from "../../assets/img/realOwlLogo.svg";

import Switch from "@mui/material/Switch";

const WhoAreWeSection = () => {
  const [showTeam, setShowTeam] = useState(false);

  const toggleShowTeam = () => {
    setShowTeam(!showTeam);
  };

  const teamMembers = [
    {
      name: "Syed Maududi",
      image: SyedImage,
      degree: "5th Year MBBS @ ICL",
      role: "Question Writer",
    },
    {
      name: "Roshaan Choudhary",
      image: RoshaanImage,
      degree: "4th Year MBBS @ ICL",
      role: "Question Writer",
      alternateBlob: true,
    },
    {
      name: "Haseeb Tahir",
      image: HaseebImage2,
      degree: "4th Year BDS  @  UoM",
      role: "Question Writer",
    },

    {
      name: "Aisha Kareem",
      image: OwlLogoSvg,
      degree: "4th Year BDS  @  UoM",
      role: "Brand Ambassador",
    },

    {
      name: "Umair Shams",
      image: UmairImage,
      degree: "4th Year MBBS  @  ICL",
      role: "Question Writer",
    },
    {
      name: "Syeda Kazmi",
      image: OwlLogoSvg,
      degree: "4th Year BDS  @  UoM",
      role: "Brand Ambassador",
    },

    {
      name: "Izzah Choudhary",
      image: OwlLogoSvg,
      degree: "A-Levels",
      role: "Digital Marketer",
    },

    {
      name: "Ebadur Rahman",
      image: EbadurImage,
      degree: "MEng Engineering @  UoM",
      role: "Software Developer",
      alternateBlob: true,
    },

    {
      name: "Qasim Ahmed",
      image: OwlLogoSvg,
      degree: "5th Year MBBS  @  ICL",
      role: "Question Writer",
    },

    {
      name: "Furqan Khan",
      image: OwlLogoSvg,
      degree: "BSc Computer Science  @  MMU",
      role: "Software Developer",
    },

    // ... more team members
  ];

  return (
    <section className="who-are-we-section">
      <h2>
        Who Are <span className="blue-text">We</span>?
      </h2>

      <Switch checked={showTeam} onChange={() => setShowTeam(!showTeam)} />

      {showTeam ? (
        <div className="team-images">
          {teamMembers.map((member) => (
            <div key={member.name} className="team-member">
              <div
                className={`team-blob ${
                  member.image.endsWith(".svg") ? "team-blob-svg" : ""
                } ${member.alternateBlob ? "team-blob-second" : ""}`}
                style={{ backgroundImage: `url(${member.image})` }}
              ></div>

              <span className="team-name">{member.name}</span>
              <span className="team-degree">{member.degree}</span>
              <span className="team-role">{member.role}</span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="who-are-we-content">
            <div className="blob">
              <div className="blob-text">
                We are a vibrant community comprising medics, dentist, and
                engineers, each bringing a unique blend of passion and expertise
                to the table.
              </div>
            </div>
            <WhoAreWeOwlQuestion className="WhoAreWeOwlQuestion" />
            <div className="blob blob-second">
              <div className="blob-text">
                With each of us hailing from unique backgrounds, we've merged
                our expertise to cover all angles and fulfil the needs of the
                Preclinique community.
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default WhoAreWeSection;
