import React from "react";
import "./App.css";
import Home from "./components/pages/HomePage/Home";
import Services from "./components/pages/Services/Services";
import Products from "./components/pages/Products/Products";
import LogIn from "./components/pages/LogIn/LogIn";
import Register from "./components/pages/Register/Register";
import Dashboard from "./components/pages/Dashboard/DashboardPages/Dashboard";
import DashboardHomePage from "./components/pages/Dashboard/DashboardPages/DashboardHomePage";
import DashboardQuestionBank from "./components/pages/Dashboard/DashboardPages/DashboardQuestionBank/DashboardQuestionBank";
import DashboardQuestionInterface from "./components/pages/Dashboard/DashboardPages/DashboardQuestionBank/DashboardQuestionInterface";
import DashboardQusetionResult from "./components/pages/Dashboard/DashboardPages/DashboardQuestionBank/DashboardQuestionInterfaceComponents/DashboardQuestionsResults";
import DashboardSAQ from "./components/pages/Dashboard/DashboardPages/DashboardSAQ";
import DashboardReviewPage from "./components/pages/Dashboard/DashboardPages/DashboardReviewPage";
import DashboardSettings from "./components/pages/Dashboard/DashboardPages/DashboardSettings";
import DashboardAnalyticsPage from "./components/pages/Dashboard/DashboardPages/DashboardAnalyticsPage";
import ForgotPassword from "./components/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword/ResetPassword";
import ConfirmEmail from "./components/pages/ConfirmEmail/ConfirmEmail";
import Contact from "./components/pages/Contact/Contact";
import TermsAndConditions from "./components/pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import PaymentPage from "./components/pages/Dashboard/DashboardPages/PaymentPage";

import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/UIElements/Navbar";
import Footer from "./components/pages/Footer/Footer";
import CookieManager from "./components/UIElements/CookieManager";
import WhatsNew from "./components/pages/WhatsNew/WhatsNew";

import CardSection from "./components/UIElements/CardSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import DashboardQuestionInterfaceForId from "./components/pages/Dashboard/DashboardPages/DashboardQuestionBank/DashboardQuestionInterfaceForId";

const PrivateRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{isAuth ? <Outlet /> : <Navigate to="/login" />} </>;
};
const RestrictedRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{!isAuth ? <Outlet /> : <Navigate to="/dashboard/homepage" />} </>;
};

const RestrictedLayout = () => {
  const location = useLocation();
  const hideNavbarFooterPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/confirm-account",
  ];

  const shouldShowNavbarFooter = !hideNavbarFooterPages.includes(
    location.pathname
  );

  return (
    <>
      {shouldShowNavbarFooter && <Navbar />}
      <Outlet />
      {shouldShowNavbarFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <CookieManager />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="payment" element={<PaymentPage />} />
              <Route path="homepage" element={<DashboardHomePage />} />
              <Route path="question-bank" element={<DashboardQuestionBank />} />
              <Route
                path="question-bank/questions"
                element={<DashboardQuestionInterface />}
              />
              <Route
                path="question-bank/questionsById"
                element={<DashboardQuestionInterfaceForId />}
              />
              <Route
                path="question-bank/questions/results"
                element={<DashboardQusetionResult />}
              />
              <Route path="saq" element={<DashboardSAQ />} />
              <Route path="review" element={<DashboardReviewPage />} />
              <Route path="settings" element={<DashboardSettings />} />
              <Route path="analytics" element={<DashboardAnalyticsPage />} />
            </Route>
          </Route>

          <Route element={<RestrictedRoutes />}>
            <Route element={<RestrictedLayout />}>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<Home />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} /> */}
              <Route path="/confirm-account" element={<ConfirmEmail />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/whats-new" element={<WhatsNew />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
