import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardContent, Typography, Button, Dialog } from "@mui/material";
import "./DashboardPagesStyles/DashboardSAQ.css";
import ThinkingOwl from "../../../../assets/img/aqe.svg";
import SickleCellImage from "../../../../assets/img/sickleCellImage.jpg";

//import "./DashboardQuestionInterfaceStyles/DashboardQuestionInterface.css";

import IconButton from "@mui/material/IconButton";
import FlagIcon from "@mui/icons-material/Flag";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

/** Redux store import */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscriptionStatus } from "../../../../api/auth";


function InfoDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Card>
        <CardContent>
          <Typography variant="h6">SAQs</Typography>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            We are currently testing a new format for our questions. Your
            participation in these questions is for testing purposes only and
            will not affect your statistics or scores. We appreciate your
            feedback on this new format. Thank you for helping us improve!
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button onClick={onConfirm}>Confirm</Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </CardContent>
      </Card>
    </Dialog>
  );
}

const questions = [
  {
    background: `A 58-year-old male presents to the emergency department with sudden, severe chest pain that he describes as a crushing sensation radiating to his left arm. He reports that the pain started 30 minutes ago while he was doing yard work and has not subsided. The patient is visibly anxious and diaphoretic. His medical history is significant for hypertension and type 2 diabetes mellitus. He admits to a diet high in saturated fats and mentions that he has been previously advised about elevated cholesterol levels but has been non-compliant with his lipid-lowering medication called cholestyramine (a bile acid sequestrant).
    On examination, the patient appears overweight with a BMI of 31. His blood pressure is 165/95 mmHg, heart rate is 102 bpm, and respiratory rate is 20 breaths per minute. An ECG shows ST-segment elevation in the anterior leads. The patient's blood tests reveal elevated cardiac biomarkers and a lipid profile indicating significantly elevated total cholesterol and LDL cholesterol levels`,
    parts: [
      {
        question:
          "1. Considering the patient's presentation and history, what risk factor is associated with the development of a blocked artery in myocardial infarction? (1 mark)",
        correctAnswer: "Hypercholesterolaemia/ high cholesterol (1)",
        additionalInfo:
          "We can split the biosynthesis of cholesterol into 3 main parts:",
      },
      {
        question:
          "2. What is the name of the molecule synthesised in the first stage of cholesterol biosynthesis and where does this occur? (1 mark) ",
        correctAnswer: `Synthesis of isopenteny! pyrophosphate (1/2)
        Cytoplasm (1/2)`,
      },

      {
        question:
          "3. What is the name of the molecule synthesised in the second stage of cholesterol biosynthesis and where does this occur? (1 mark) ",
        correctAnswer: `Formation of squalene (1/2)
        Cytoplasm (1/2)`,
      },

      {
        question:
          "4. In the second stage of cholesterol biosynthesis, which specific substrates combine, and what is the nature of the biochemical reaction involved in forming the resultant product? (1 mark) ",
        correctAnswer: `6 molecules of isopenteny! prophosphate (1/2)
        Condensation reaction (1/2)`,
      },

      // More parts as needed
    ],
  },
  {
    background: `Patients may concurrently be carriers of multiple genetic disorders.`,
    parts: [
      {
        question:
          "1. Given a scenario where one parent is a carrier of the beta thalassemia trait and the other parent is a carrier of the haemoglobin S (sickle cell) trait, what is the probability that their offspring will inherit sickle-beta thalassemia? (1 mark)",
        correctAnswer: `25% or 1/4 (1)  Explanation:
    
        Beta thalassemia and sickle cell disease are both inherited blood disorders affecting haemoglobin.
        
        Beta thalassemia trait indicates that the individual carries one normal haemoglobin gene (A) and one mutated gene (Bt)
        
        Haemoglobin S trait indicates that the individual carries one normal haemoglobin gene (A) and haemoglobin S gene (S)
        
        When two carriers of these traits have a child, the child will inherit one gene from each parent, therefore the possible combinations will be:
        
        1. AA (normal haemoglobin)
        2. AS (sickle cell trait)
        3. ABt (beta thalassemia trait)
        4. SBt (sickle-beta thalassemia) 
        
        Therefore, there is a 1/4 chance that a child from these parents will have sickle-beta thalassemia, a condition where the individual has one sickle gene and one beta thalassemia gene.`,
        additionalInfo:
          "This is a blood film taken from a patient with sickle cell:",
      },
      {
        question:
          "2. Identify two additional types of cell that can be observed in this blood film besides sickle cells. (1 mark) ",
        correctAnswer: `Target cells (1/2)/ Howell Jolly bodies (1/2)/ Boat cells (1/2)`,
      },

      {
        question:
          "3. Explain the mechanism by which patients with sickle cell disease have an increased likelihood of experiencing jaundice and the formation of gallstones. (2.5 marks) ",
        correctAnswer: `HbS causes red blood cells to become rigid and form a sickle shape (1/2)

        Sickled cells are more prone to undergo haemolysis (1/2)
        
        Increased haemolysis results in elevated bilirubin (1/2)
        
        Build up of bilirubin leads to jaundice (1/2)
        
        Normally, the liver can process bilirubin, which is excreted in bile. Gallstones occur when the bilirubin and cholesterol in bile crystallise, forming stones that can cause blockage and pain (1/2)`,
      },

      {
        question:
          "4. Sickle cell disease can lead to a variety of symptoms. Based on your knowledge of oxygen saturation curves, describe the pathophysiology of a thrombotic/vaso-occlusive crisis. (2.5 marks)  ",
        correctAnswer: `HbS has a lower affinity for oxygen vs normal Hb  (1/2)

        Hypoxia leads to polymerisation of HbS (1/2)
        
        This leads to sickling of red blood cells (1/2)
        
        Sickle cells are sticky and easily accumulate (1/2)
        
        Blocking microcirculation and blood flow to various organs / bone leading to ischaemia and pain (1/2)
        Explanation: A vaso-occlusive crisis occurs when sickled red blood cells obstruct capillaries and restrict blood flow to organs leading to ischaemia and pain. These crises are precipitated by conditions that reduce oxygen levels in the blood, such as infections, stress, temperature changes, and dehydration promoting red cell sickling. Symptoms include severe pain, often in the bones, abdomen, chest and joints, along with fever, and in severe cases organ damage.
Management focuses on pain relief, hydration and removing underlying/precipitating factors`,
      },

      {
        question: `5. A 4-year-old with a family history of sickle cell trait presents after multiple, asymptomatic splenic vaso-occlusions detected during routine assessments. Upon further investigations sickle cells are present on blood smear and a reduced spleen size is observed on ultrasound. 
    What is this childs status known as?  (1 mark)`,
        correctAnswer: `Functional hyposplenism (1)`,
      },

      {
        question: `6. What type of infections are these patients most at risk of and how can this risk be reduced? (1 mark)`,
        correctAnswer: `At risk of encapsulated bacterial infection (1/2)

        Immunisations/ Prophylactic antibiotics (1/2)`,
      },

      // More parts as needed
    ],
  },
  {
    background: `A 45-year-old female with a medical history of obesity and hypertension is brought to the clinic for routine blood tests. Her blood glucose level is found to be higher than normal. You are tasked with explaining the normal physiological responses to an increase in blood glucose and discussing the hormonal regulation involved in this process.`,
    parts: [
      {
        question:
          "1. Describe the initial physiological response of pancreatic beta-cells to an increase in blood glucose concentration. (1 mark)",
        correctAnswer: `Identification of insulin as the hormone secreted by pancreatic beta-cells. (1/2) |
        Explanation of how glucose triggers insulin release via the glucose-sensor mechanism of glucokinase.`,
      },
      {
        question: `2. Discuss two actions of insulin on peripheral tissues that help lower blood glucose levels.
          (1 mark)`,
        correctAnswer: `Insulin facilitates glucose uptake in cells, especially muscle and adipose tissue via the GLUT4 transporter. (1/2)
        Insulin promotes glycogenesis and inhibits glycogenolysis in the liver. (1/2)`,
        additionalInfo:
          "Also accept: decreases lipolysis and increases lipogenesis in adipose tissue/ increases amino acid transport into muscle cells, which can be used for protein synthesis rather than gluconeogenesis|",
      },

      {
        question:
          "3. Explain the 'incretin effect, its physiological significance in glucose metabolism and pharmacological potential. (4 marks) ",
        correctAnswer: `The 'incretin effect' refers to the phenomenon where oral ingestion of glucose results in a greater insulin response than an equivalent amount of glucose delivered intravenously. (1)
        This is due to the effect of incretin hormones like GLP-1, released by the gut in response to nutrient intake and increase insulin secretion from pancreatic beta cells. (1)|
        Physiologically, incretin hormones like GLP-1 enhance insulin's ability to lower blood glucose levels following meals by suppressing glucagon release and inducing satiety therefore regulating postprandial glucose levels. (1)
        Due to its glucose lowering effects, GLP-1 analogs or DPP-4 inhibitors are used in the treatment of type 2 diabetes. (1)`,
      },

      {
        question:
          "4. Given her medical history and the presence of hyperglycaemia, discuss the potential impact of long-term elevated blood glucose on the patient's overall health and important considerations for managing hyperglycaemia. (4 marks)  ",
        correctAnswer: `Long-term elevated blood glucose levels can lead to the development of type 2 diabetes mellitus. (1)
        Persistent hyperglycaemia can damage blood vessels and nerves, leading to complications.
        Complications include: cardiovascular disease, neuropathy, nephropathy, retinopathy and increased susceptibility to infections. (1)
        Managing hyperglycaemia involves lifestyle changes such as diet and exercise as well as medical interventions such as insulin therapy. (1)
        Regular monitoring of blood glucose levels, adherence to treatment and lifestyle modification are essential to control blood glucose levels and minimise the risk of developing complications. (1)`,
      },

      // More parts as needed
    ],
  },
  {
    background: `A 55-year-old female with a history of hypertension presents to the emergency department with sudden onset of left-sided weakness and difficulty speaking. A CT scan shows an area of infarction in the right cerebral hemisphere.`,
    parts: [
      {
        question:
          "1. Identify the lobe of the brain primarily involved in planning, decision-making, and voluntary movement, and describe one potential deficit this patient might experience if the left-side and primarily the lateral surface of this lobe is affected. (1 mark)",
        correctAnswer: `The frontal Lobe is primarily involved in planning, decision-making, and voluntary movement (1/2), therefore a potential deficit if the left-side of the lobe is affected could be paralysis/weakness in right-sided muscles in the face and/or upper extremities. (1/2)`,
        additionalInfo:
          "Note: The lateral surface of the frontal lobe is primarily supplied by the middle cerebral artery and includes parts of the primary motor and sensory cortices that control and receive sensation from the upper extremities and face. Whereas the anterior cerebral artery supplies the medial and superior parts of the frontal lobe which controls and receives sensations from the lower extremities",
      },
      {
        question: `2. Name the lobe that is primarily responsible for processing visual information and discuss a possible symptom if this lobe is damaged in the patient's current condition. (1 mark)`,
        correctAnswer: `Occipital lobe is primarily responsible for processing visual information (1/2). A symptom of damage to this lobe might be visual defects or difficulty with visual perception (1/2)`,
      },

      {
        question:
          "3. Explain the role of the parietal lobe in integrating sensory information, and infer a specific sensory deficit that could result from damage to this region. (1 mark)",
        correctAnswer: `The parietal lobe integrates sensory information from various parts of the body, providing spatial orientation and information about objects in the environment. (1/2)
        A specific sensory deficit could be loss of sensation or inability to locate and recognise objects by touch on the opposite side of the body from where the damage occurred. (1/2)`,
      },

      {
        question:
          "4. Determine which cortical area is likely to be involved given the patient's difficulty with speech. (1 mark)  ",
        correctAnswer: `Broca's area is likely to be involved, given the patient's difficulty with speech. This area is associated with speech production. (1)`,
        additionalInfo:
          "Note: Damage to this area typically results in expressive aphasia, where a patient understands spoken language but struggles to articulate speech and form coherent sentences.",
      },

      {
        question:
          "5. Explain the expected functional loss if the primary motor cortex for the arm is affected in the patient's right hemisphere. (1 mark)  ",
        correctAnswer: `If the primary motor cortex for the arm is affected in the patient's right hemisphere, the expected functional loss would be weakness or paralysis of the arm on the left side (1/2), due to the decussation of motor fibres in the medulla (1/2).`,
        additionalInfo:
          "In a different scenario, you are on placement and the on-call doctor asks you to perform a cranial nerve examination on a patient. You ask the patient to cover their right eye, and you cover your own left eye. You then ask the patient to look into your open eye. With your finger positioned halfway between yourself and the patient, you assess whether the patient can see your finger move when it is to the left and right of them. However, in both eyes, when you move your finger on their right side, they are able to see it. Yet, when you move your finger to their left side, they are unable to see the movement in either eye.",
      },

      {
        question:
          "6. Identify the name of this test and the specific nerve being assessed? (2 marks)  ",
        correctAnswer: `Visual field test (1)
        Optic nerve/ cranial nerve II (1)`,
      },
      {
        question:
          "7. Name the deficit and explain where the damage will likely be seen as well as the vessel occluded if this patient suffered a stroke. (2 marks) ",
        correctAnswer: `Left sided homonymous hemianopia (1)
        The patient has loss of the left half of the field of vision in both eyes, suggesting damage to the right visual cortex which is supplied by the posterior cerebral artery (1)`,
      },

      {
        question:
          "Name another cause resulting in the same visual symptoms. (1 mark) ",
        correctAnswer: `Right-sided brain tumour affecting visual cortex (1)
        Accept any other right-sided pathology affecting the right visual cortex (1)`,
      },
    ],
  },
  // More questions as needed
];

const DashboardSAQ = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [maxPartIndexReached, setMaxPartIndexReached] = useState({});
  const [dialogOpen, setDialogOpen] = useState(true); // State to control dialog visibility
    const navigate = useNavigate();
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        async function fetchSubscriptionStatus() {
            try {
                const subscriptionResponse = await getSubscriptionStatus();
                const status = subscriptionResponse.status;
                setSubscriptionStatus(status);

                // Redirect based on subscription status
                if (status === 'active') {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to dashboard...');
                    
                } else {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to payment...');
                    navigate('/dashboard/payment');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        }

        fetchSubscriptionStatus();
    }, []);




  useEffect(() => {
    // Initialize dialog
    setDialogOpen(true);
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    // Any additional logic when the user confirms to proceed
  };

  const handleDialogCancel = () => {
    navigate("/dashboard/homepage"); // Navigate to homepage
  };

  useEffect(() => {
    // When switching questions, set currentPartIndex to the max part reached for that question
    setCurrentPartIndex(maxPartIndexReached[currentQuestionIndex] || 0);
  }, [currentQuestionIndex, maxPartIndexReached]);

  const handleInputChange = (partIndex, e) => {
    setUserAnswers({
      ...userAnswers,
      [`${currentQuestionIndex}-${partIndex}`]: e.target.value,
    });
  };

  useEffect(() => {
    // Initialize maxPartIndexReached for the current question if not already set
    if (maxPartIndexReached[currentQuestionIndex] === undefined) {
      setMaxPartIndexReached((prev) => ({
        ...prev,
        [currentQuestionIndex]: 0,
      }));
    }

    // When switching questions, set currentPartIndex to the max part reached for that question
    setCurrentPartIndex(maxPartIndexReached[currentQuestionIndex] || 0);
  }, [currentQuestionIndex, maxPartIndexReached]);

  const handleSubmitPart = (partIndex) => {
    const newMaxPartIndex = Math.max(
      partIndex + 1,
      maxPartIndexReached[currentQuestionIndex] || 0
    );
    setMaxPartIndexReached({
      ...maxPartIndexReached,
      [currentQuestionIndex]: newMaxPartIndex,
    });
    if (newMaxPartIndex < questions[currentQuestionIndex].parts.length) {
      setCurrentPartIndex(newMaxPartIndex);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const currentQuestion = questions[currentQuestionIndex];

  // Determine the maximum part index to display for the current question
  const maxIndexToDisplay = Math.max(
    currentPartIndex,
    maxPartIndexReached[currentQuestionIndex] || 0
  );

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <InfoDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
      />
      <Container
        height="100%"
        maxWidth="1600px"
        sx={{ mt: 4, mb: 4, height: "calc(100vh - 8rem)" }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",

            minHeight: "80vh", // Setting minimum height
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div className="nav-button-container">
            <div className="inner-container">
              <div className="question-counter">
                Question {currentQuestionIndex + 1} of {questions.length}
              </div>
              <Box className="mui-nav-box-root">
                {currentQuestionIndex > 0 && (
                  <IconButton
                    onClick={handlePreviousQuestion}
                    aria-label="previous"
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                )}
                {currentQuestionIndex < questions.length - 1 && (
                  <IconButton onClick={handleNextQuestion} aria-label="next">
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </Box>
            </div>
          </div>
          <div className="parent-content-container">
            <div className="content-container-saq">
              <div className="background-info-header">
                {" "}
                {/* Wrapper for the heading */}
                <h2>Background Information</h2>
              </div>
              <div className="part-container">
                <p>{currentQuestion.background}</p>
              </div>

              {currentQuestion.parts
                .slice(0, maxIndexToDisplay + 1)
                .map((part, index) => (
                  <div key={index}>
                    <h3>{part.question}</h3>
                    <input
                      className="fancy-text-input"
                      type="text"
                      value={
                        userAnswers[`${currentQuestionIndex}-${index}`] || ""
                      }
                      onChange={(e) => handleInputChange(index, e)}
                      placeholder="Type your answer here..."
                      disabled={index !== currentPartIndex}
                      style={{ marginBottom: "20px" }}
                    />
                    {index < currentPartIndex && (
                      <div className="correct-answer">
                        {" "}
                        {/* Applying the correct-answer class */}
                        <strong>Correct Answer: </strong>
                        {part.correctAnswer}
                        {part.additionalInfo && (
                          <p className="additional-info">
                            {part.additionalInfo}
                          </p>
                        )}
                        {currentQuestionIndex === 1 && index === 0 && (
                          <img
                            src={SickleCellImage}
                            alt="Blood film of a patient with sickle cell"
                            style={{ width: "500px", height: "auto" }}
                          />
                        )}
                      </div>
                    )}
                    {index === currentPartIndex && (
                      <button
                        className="reveal-button"
                        onClick={() => handleSubmitPart(index)}
                      >
                        {index === currentQuestion.parts.length - 1
                          ? "Submit Answer"
                          : "Reveal Answer"}
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {currentQuestionIndex < questions.length &&
            maxPartIndexReached[currentQuestionIndex] >=
              currentQuestion.parts.length && (
              <div className="completed-message-container">
                All parts for this question are completed!
              </div>
            )}

          <div className="submit-button-container"></div>
        </Paper>
      </Container>
    </Box>
  );
};

export default DashboardSAQ;
