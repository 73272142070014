import React, { useState } from "react";
import { Button } from "../../../UIElements/Button";
import {
    Box,
    Container,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Switch,
    Typography,
} from "@mui/material";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import cardPayment from "../../../../assets/img/cardPayment.svg";
import { loadStripe } from "@stripe/stripe-js";
import { stripeCheckoutMonthly, stripeCheckoutYearly } from "../../../../api/auth";
import { useSelector } from "react-redux";
import { FaFire } from "react-icons/fa";
import { BsXDiamondFill } from "react-icons/bs";
import { GiCrystalize } from "react-icons/gi";
import "./Pricing.css";

const stripePromise = loadStripe("pk_live_51P7OvaRrXxqfPmR1caTAZrt2bxS3agOyJuYgNUs51OfYgGvaGn1EHYKw2BzEkI4aSkwbwfNXDLyrJxyYt7FDS9jf00HhbKhnJq");

const PaymentPage = () => {
    const [university, setUniversity] = useState("Imperial College London");
    const [isAnnual, setIsAnnual] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const userId = useSelector((state) => state.auth.userId);


    const handleCheckoutMonthly = async () => {
        try {
            if (!userId) {
                console.error("UserId is not available.");
                return;
            }

            console.log("UserId:", userId);

            const session = await stripeCheckoutMonthly(userId);
            console.log("Stripe Checkout session:", session);

            if (session.data.sessionId) {
                setSessionId(session.data.sessionId);
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                    sessionId: session.data.sessionId,
                });

                if (result.error) {
                    console.error(result.error.message);
                }
            } else {
                console.error('Session ID not found in the response');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleCheckoutYearly = async () => {
        try {
            if (!userId) {
                console.error("UserId is not available.");
                return;
            }

            console.log("UserId:", userId);

            const session = await stripeCheckoutYearly(userId);
            console.log("Stripe Checkout session:", session);

            if (session.data.sessionId) {
                setSessionId(session.data.sessionId);
                const stripe = await stripePromise;
                const result = await stripe.redirectToCheckout({
                    sessionId: session.data.sessionId,
                });

                if (result.error) {
                    console.error(result.error.message);
                }
            } else {
                console.error('Session ID not found in the response');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const buttonOnClick = isAnnual ? handleCheckoutYearly : handleCheckoutMonthly;


    const features = {
        monthly: [
            {
                title: "Complete Access",
                price: "2.99 GBP",
                details: ["700+ Questions", "300+ VSAQs", "Comprehensive SAQs"],
            },
        ],
        annual: [
            {
                title: "Complete Access",
                price: "19.99 GBP",
                details: ["700+ Questions", "300+ VSAQs", "Comprehensive SAQs"],
            },
        ],
    };

    const selectedFeatures = isAnnual ? features.annual : features.monthly;

    return (
        <Container className="paymentContainer">
            

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginY={2}
            >
                <div style={{ marginRight: "8px" }}>
                    <Switch
                        checked={isAnnual}
                        onChange={() => setIsAnnual(!isAnnual)}
                        name="pricingToggle"
                        color="primary"
                        mr={2}
                    />
                </div>
            </Box>

            <div className="pricing__section">
                <div className="pricing__wrapper">
                    <div className="pricing__container">
                        <h1 className="pricing__heading">
                            {isAnnual ? "Annual" : "Monthly"}
                        </h1>
                        {selectedFeatures.map((plan, index) => (
                            <Link
                                to="/register"
                                key={index}
                                className="pricing__container-card"
                            >
                                <div className="pricing__container-cardInfo">
                                    <div className="icon">
                                        <img
                                            src={cardPayment}
                                            alt="card payment"
                                            style={{ width: 100, height: 100 }}
                                        />
                                    </div>
                                    <h3>{plan.title}</h3>
                                    <h4>{plan.price}</h4>
                                    <p style={{ fontStyle: "italic" }}>
                                        {isAnnual ? "per year" : "per month"}
                                    </p>
                                    <ul className="pricing__container-features">
                                        {plan.details.map((detail, idx) => (
                                            <li key={idx}>{detail}</li>
                                        ))}
                                    </ul>
                                    <Button buttonSize="btn--wide" onClick={buttonOnClick}>
                                        Choose Plan
                                    </Button>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PaymentPage;