import { createSlice } from "@reduxjs/toolkit";

export const selectedSubcategoriesSlice = createSlice({
  name: "selectedSubcategories",
  initialState: [],
  reducers: {
    setSelectedSubcategories: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSelectedSubcategories } = selectedSubcategoriesSlice.actions;

export default selectedSubcategoriesSlice.reducer;
