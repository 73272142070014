import React from "react";
import "./TermsConditions.css";

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms & Conditions</h1>
            <p> Last updated January 07, 2024 </p>

            <h2>AGREEMENT TO OUR LEGAL TERMS </h2>
            <p>
                We are Preclinique ('Company', 'we', 'us', or 'our'), a company
                registered in the United Kingdom at 29 Thoresway Road, Manchester M13
                0XN.
            </p>
            <p>
                We operate the website https://www.preclinique.co.uk/ (the 'Site'), as
                well as any other related products and services that refer or link to
                these legal terms (the 'Legal Terms') (collectively, the 'Services').
                You can contact us by phone at 07397047033, email at
                admin@preclinique.co.uk, or by mail to 29 Thoresway Road, Manchester M13
                0XN, United Kingdom.
            </p>
            <p>
                These Legal Terms constitute a legally binding agreement made between
                you, whether personally or on behalf of an entity ('you'), and
                Preclinique, concerning your access to and use of the Services. You
                agree that by accessing the Services, you have read, understood, and
                agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
                ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
                THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>
            <p>
                We will provide you with prior notice of any scheduled changes to the
                Services you are using. The modified Legal Terms will become effective
                upon posting or notifying you by admin@preclinique.co.uk, as stated in
                the email message. By continuing to use the Services after the effective
                date of any changes, you agree to be bound by the modified terms.
            </p>
            <p>
                The Services are intended for users who are at least 18 years old.
                Persons under the age of 18 are not permitted to use or register for the
                Services.
            </p>

            <h2>TABLE OF CONTENTS</h2>
            <ol>
                <li>OUR SERVICES</li>
                <li>INTELLECTUAL PROPERTY RIGHTS</li>
                <li>USER REPRESENTATIONS</li>
                <li> USER REGISTRATION</li>
                <li> PURCHASES AND PAYMENT</li>
                <li>CANCELLATION</li>
                <li>PROHIBITED ACTIVITIES</li>
                <li>USER GENERATED CONTRIBUTIONS</li>
                <li>CONTRIBUTION LICENCE</li>
                <li> GUIDELINES FOR REVIEWS</li>
                <li>THIRD-PARTY WEBSITES AND CONTENT</li>
                <li>ADVERTISERS</li>
                <li>SERVICES MANAGEMENT</li>

                <li>PRIVACY POLICY</li>
                <li> TERM AND TERMINATION</li>
                <li> MODIFICATIONS AND INTERRUPTIONS</li>
                <li>GOVERNING LAW</li>
                <li>DISPUTE RESOLUTION</li>

                <li>CORRECTIONS</li>
                <li> DISCLAIMER</li>
                <li>LIMITATIONS OF LIABILITY</li>
                <li>INDEMNIFICATION</li>
                <li>USER DATA</li>

                <li> ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
                <li>MISCELLANEOUS</li>
                <li> CONTACT US</li>
            </ol>

            <h2>1. OUR SERVICES </h2>
            <p>
                The information provided when using the Services is not intended for
                distribution to or use by any person or entity in any jurisdiction or
                country where such distribution or use would be contrary to law or
                regulation or which would subject us to any registration requirement
                within such jurisdiction or country. Accordingly, those persons who
                choose to access the Services from other locations do so on their own
                initiative and are solely responsible for compliance with local laws, if
                and to the extent local laws are applicable.{" "}
            </p>

            <h2>2. INTELLECTUAL PROPERTY RIGHTS </h2>
            <p>
                Our intellectual property We are the owner or the licensee of all
                intellectual property rights in our Services, including all source code,
                databases, functionality, software, website designs, audio, video, text,
                photographs, and graphics in the Services (collectively, the 'Content'),
                as well as the trademarks, service marks, and logos contained therein
                (the 'Marks'). Our Content and Marks are protected by copyright and
                trademark laws (and various other intellectual property rights and
                unfair competition laws) and treaties in the United States and around
                the world. The Content and Marks are provided in or through the Services
                'AS IS' for your personal, non-commercial use only. Your use of our
                Services Subject to your compliance with these Legal Terms, including
                the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
                non-transferable, revocable licence to: 1) access the Services; and 2)
                download or print a copy of any portion of the Content to which you have
                properly gained access. solely for your personal, non-commercial use.
                Except as set out in this section or elsewhere in our Legal Terms, no
                part of the Services and no Content or Marks may be copied, reproduced,
                aggregated, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or otherwise
                exploited for any commercial purpose whatsoever, without our express
                prior written permission. If you wish to make any use of the Services,
                Content, or Marks other than as set out in this section or elsewhere in
                our Legal Terms, please address your request to:
                admin@preclinique.co.uk. If we ever grant you the permission to post,
                reproduce, or publicly display any part of our Services or Content, you
                must identify us as the owners or licensors of the Services, Content, or
                Marks and ensure that any copyright or proprietary notice appears or is
                visible on posting, reproducing, or displaying our Content. We reserve
                all rights not expressly granted to you in and to the Services, Content,
                and Marks. Any breach of these Intellectual Property Rights will
                constitute a material breach of our Legal Terms and your right to use
                our Services will terminate immediately.{" "}
            </p>

            <b>Your submissions</b>
            <p>
                Please review this section and the 'PROHIBITED ACTIVITIES' section
                carefully prior to using our Services to understand the (a) rights you
                give us and (b) obligations you have when you post or upload any content
                through the Services. Submissions: By directly sending us any question,
                comment, suggestion, idea, feedback, or other information about the
                Services (Submissions'), you agree to assign to us all intellectual
                property rights in such Submission. You agree that we shall own this
                Submission and be entitled to its unrestricted use and dissemination for
                any lawful purpose, commercial or otherwise, without acknowledgment or
                compensation to you. You are responsible for what you post or upload: By
                sending us Submissions through any part of the Services you: ■ confirm
                that you have read and agree with our 'PROHIBITED ACTIVITIES' and will
                not post, send, publish, upload, or transmit through the Services any
                Submission that is illegal, harassing, hateful, harmful, defamatory,
                obscene, bullying, abusive, discriminatory, threatening to any person or
                group, sexually explicit, false, inaccurate, deceitful, or misleading; ■
                to the extent permissible by applicable law, waive any and all moral
                rights to any such Submission; ■ warrant that any such Submission are
                original to you or that you have the necessary rights and licences to
                submit such Submissions and that you have full authority to grant us the
                above-mentioned rights in relation to your Submissions; and ■ warrant
                and represent that your Submissions do not constitute confidential
                information. You are solely responsible for your Submissions and you
                expressly agree to reimburse us for any and all losses that we may
                suffer because of your breach of (a) this section, (b) any third party's
                intellectual property rights, or (c) applicable law.
            </p>

            <h2>3. USER REPRESENTATIONS</h2>
            <p>
                By using the Services, you represent and warrant that: (1) all
                registration information you submit will be true, accurate, current, and
                complete; (2) you will maintain the accuracy of such information and
                promptly update such registration information as necessary; (3) you have
                the legal capacity and you agree to comply with these Legal Terms; (4)
                you are not a minor in the jurisdiction in which you reside; (5) you
                will not access the Services through automated or non-human means,
                whether through a bot, script or otherwise; (6) you will not use the
                Services for any illegal or unauthorised purpose; and (7) your use of
                the Services will not violate any applicable law or regulation. If you
                provide any information that is untrue, inaccurate, not current, or
                incomplete, we have the right to suspend or terminate your account and
                refuse any and all current or future use of the Services (or any portion
                thereof).
            </p>

            <h2>4. USER REGISTRATION </h2>
            <p>
                You may be required to register to use the Services. You agree to keep
                your password confidential and will be responsible for all use of your
                account and password. We reserve the right to remove, reclaim, or change
                a username you select if we determine, in our sole discretion, that such
                username is inappropriate, obscene, or otherwise objectionable.
            </p>
            <h2>5. PURCHASES AND PAYMENT</h2>
            <p>
                We accept the following forms of payment: - Visa - Mastercard - PayPal
                You agree to provide current, complete, and accurate purchase and
                account information for all purchases made via the Services. You further
                agree to promptly update account and payment information, including
                email address, payment method, and payment card expiration date, so that
                we can complete your transactions and contact you as needed. Sales tax
                will be added to the price of purchases as deemed required by us. We may
                change prices at any time. All payments shall be in GBP. You agree to
                pay all charges at the prices then in effect for your purchases and any
                applicable shipping fees, and you authorise us to charge your chosen
                payment provider for any such amounts upon placing your order. If your
                order is subject to recurring charges, then you consent to our charging
                your payment method on a recurring basis without requiring your prior
                approval for each recurring charge, until such time as you cancel the
                applicable order. We reserve the right to correct any errors or mistakes
                in pricing, even if we have already requested or received payment. We
                reserve the right to refuse any order placed through the Services. We
                may, in our sole discretion, limit or cancel quantities purchased per
                person, per household, or per order. These restrictions may include
                orders placed by or under the same customer account, the same payment
                method, and/or orders that use the same billing or shipping address. We
                reserve the right to limit or prohibit orders that, in our sole
                judgement, appear to be placed by dealers, resellers, or distributors.
            </p>

            <h2>6. CANCELLATION</h2>
            <p>
                All purchases are non-refundable. You can cancel your subsciption at any
                time by lodding into your account. Your cancellation wil ltake effect at
                the end of the current paid term.
            </p>
            <p>
                If you are unsatisfied with our Servies, please email us at
                admin@preclinique.co.uk or call us at 07397047033
            </p>

            <h2>7. PROHIBITED ACTIVITIES</h2>
            <p>
                You may not access or use the Services for any purpose other than that
                for which we make the Services available. The Services may not be used
                in connection with any commercial endeavours except those that are
                specifically endorsed or approved by us. As a user of the Services, you
                agree not to: ■ Systematically retrieve data or other content from the
                Services to create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission from us.
                ■ Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user passwords. ■
                Circumvent, disable, or otherwise interfere with security-related
                features of the Services, including features that prevent or restrict
                the use or copying of any Content or enforce limitations on the use of
                the Services and/or the Content contained therein. Disparage, tarnish,
                or otherwise harm, in our opinion, us and/or the Services. • Use any
                information obtained from the Services in order to harass, abuse, or
                harm another person. ■ Make improper use of our support services or
                submit false reports of abuse or misconduct. • Use the Services in a
                manner inconsistent with any applicable laws or regulations. Engage in
                unauthorised framing of or linking to the Services. Upload or transmit
                (or attempt to upload or to transmit) viruses, Trojan horses, or other
                material, including excessive use of capital letters and spamming
                (continuous posting of repetitive text), that interferes with any
                party&#039;s uninterrupted use and enjoyment of the Services or
                modifies, impairs, disrupts, alters, or interferes with the use,
                features, functions, operation, or maintenance of the Services. Engage
                in any automated use of the system, such as using scripts to send
                comments or messages, or using any data mining, robots, or similar data
                gathering and extraction tools. • Delete the copyright or other
                proprietary rights notice from any Content. ■ Attempt to impersonate
                another user or person or use the username of another user. Upload or
                transmit (or attempt to upload or to transmit) any material that acts as
                a passive or active information collection or transmission mechanism,
                including without limitation, clear graphics interchange formats
                (&#039;gifs&#039;), 1x1 pixels, web bugs, cookies, or other similar
                devices (sometimes referred to as &#039;spyware&#039; or &#039;passive
                collection mechanisms&#039; or &#039;pcms&#039;). ■ Interfere with,
                disrupt, or create an undue burden on the Services or the networks or
                services connected to the Services. Harass, annoy, intimidate, or
                threaten any of our employees or agents engaged in providing any portion
                of the Services to you. Attempt to bypass any measures of the Services
                designed to prevent or restrict access to the Services, or any portion
                of the Services. Copy or adapt the Services&#039; software, including
                but not limited to Flash, PHP, HTML, JavaScript, or other code. Except
                as permitted by applicable law, decipher, decompile, disassemble, or
                reverse engineer any of the software comprising or in any way making up
                a part of the Services. Except as may be the result of standard search
                engine or Internet browser usage, use, launch, develop, or distribute
                any automated system, including without limitation, any spider, robot,
                cheat utility, scraper, or offline reader that accesses the Services, or
                use or launch any unauthorised script or other software. • Use a buying
                agent or purchasing agent to make purchases on the Services. ■ Make any
                unauthorised use of the Services, including collecting usernames and/or
                email addresses of users by electronic or other means for the purpose of
                sending unsolicited email, or creating user accounts by automated means
                or under false pretences. • Use the Services as part of any effort to
                compete with us or otherwise use the Services and/or the Content for any
                revenue-generating endeavour or commercial enterprise. • Use the
                Services to advertise or offer to sell goods and services. •Sell or
                otherwise transfer your profile.
            </p>
            <h2>8. USER GENERATED CONTRIBUTIONS</h2>
            <p>
                8. USER GENERATED CONTRIBUTIONS The Services does not offer users to
                submit or post content. We may provide you with the opportunity to
                create, submit, post, display, transmit, perform, publish, distribute,
                or broadcast content and materials to us or on the Services, including
                but not limited to text, writings, video, audio, photographs, graphics,
                comments, suggestions, or personal information or other material
                (collectively, &#039;Contributions&#039;). Contributions may be viewable
                by other users of the Services and through third-party websites. As
                such, any Contributions you transmit may be treated in accordance with
                the Services&#039; Privacy Policy. When you create or make available any
                Contributions, you thereby represent and warrant that: ■ The creation,
                distribution, transmission, public display, or performance, and the
                accessing, downloading, or copying of your Contributions do not and will
                not infringe the proprietary rights, including but not limited to the
                copyright, patent, trademark, trade secret, or moral rights of any third
                party. • You are the creator and owner of or have the necessary
                licences, rights, consents, releases, and permissions to use and to
                authorise us, the Services, and other users of the Services to use your
                Contributions in any manner contemplated by the Services and these Legal
                Terms. ■ You have the written consent, release, and/or permission of
                each and every identifiable individual person in your Contributions to
                use the name or likeness of each and every such identifiable individual
                person to enable inclusion and use of your Contributions in any manner
                contemplated by the Services and these Legal Terms. • Your Contributions
                are not false, inaccurate, or misleading. Your Contributions are not
                unsolicited or unauthorised advertising, promotional materials, pyramid
                schemes, chain letters, spam, mass mailings, or other forms of
                solicitation. Your Contributions are not obscene, lewd, lascivious,
                filthy, violent, harassing, libellous, slanderous, or otherwise
                objectionable (as determined by us). Your Contributions do not ridicule,
                mock, disparage, intimidate, or abuse anyone. ■ Your Contributions are
                not used to harass or threaten (in the legal sense of those terms) any
                other person and to promote violence against a specific person or class
                of people. ■ Your Contributions do not violate any applicable law,
                regulation, or rule. Your Contributions do not violate the privacy or
                publicity rights of any third party. Your Contributions do not violate
                any applicable law concerning child pornography, or otherwise intended
                to protect the health or well-being of minors. • Your Contributions do
                not include any offensive comments that are connected to race, national
                origin, gender, sexual preference, or physical handicap. ■ Your
                Contributions do not otherwise violate, or link to material that
                violates, any provision of these Legal Terms, or any applicable law or
                regulation. Any use of the Services in violation of the foregoing
                violates these Legal Terms and may result in, among other things,
                termination or suspension of your rights to use the Services.{" "}
            </p>
            <h2>9. CONTRIBUTION LICENCE</h2>
            <p>
                You and Services agree that we may access, store, process, and use any
                information and personal data that you provide following the terms of
                the Privacy Policy and your choices (including settings). By submitting
                suggestions or other feedback regarding the Services, you agree that we
                can use and share such feedback for any purpose without compensation to
                you. We do not assert any ownership over your Contributions. You retain
                full ownership of all of your Contributions and any intellectual
                property rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or representations
                in your Contributions provided by you in any area on the Services. You
                are solely responsible for your Contributions to the Services and you
                expressly agree to exonerate us from any and all responsibility and to
                refrain from any legal action against us regarding your Contributions.
            </p>
            <h2>10. GUIDELINES FOR REVIEWS</h2>
            <p>
                {" "}
                We may provide you areas on the Services to leave reviews or ratings.
                When posting a review, you must comply with the following criteria: (1)
                you should have firsthand experience with the person/entity being
                reviewed; (2) your reviews should not contain offensive profanity, or
                abusive, racist, offensive, or hateful language; (3) your reviews should
                not contain discriminatory references based on religion, race, gender,
                national origin, age, marital status, sexual orientation, or disability;
                (4) your reviews should not contain references to illegal activity; (5)
                you should not be affiliated with competitors if posting negative
                reviews; (6) you should not make any conclusions as to the legality of
                conduct; (7) you may not post any false or misleading statements; and
                (8) you may not organise a campaign encouraging others to post reviews,
                whether positive or negative. We may accept, reject, or remove reviews
                in our sole discretion. We have absolutely no obligation to screen
                reviews or to delete reviews, even if anyone considers reviews
                objectionable or inaccurate. Reviews are not endorsed by us, and do not
                necessarily represent our opinions or the views of any of our affiliates
                or partners. We do not assume liability for any review or for any
                claims, liabilities, or losses resulting from any review. By posting a
                review, you hereby grant to us a perpetual, non-exclusive, worldwide,
                royalty-free, fully paid, assignable, and sublicensable right and
                licence to reproduce, modify, translate, transmit by any means, display,
                perform, and/or distribute all content relating to review.
            </p>
            <h2>11. THIRD-PARTY WEBSITES AND CONTENT</h2>
            <p>
                {" "}
                The Services may contain (or you may be sent via the Site) links to
                other websites (Third-Party Websites&#039;) as well as articles,
                photographs, text, graphics, pictures, designs, music, sound, video,
                information, applications, software, and other content or items
                belonging to or originating from third parties (&quot;Third-Party
                Content&#039;). Such Third-Party Websites and Third- Party Content are
                not investigated, monitored, or checked for accuracy, appropriateness,
                or completeness by us, and we are not responsible for any Third-Party
                Websites accessed through the Services or any Third-Party Content posted
                on, available through, or installed from the Services, including the
                content, accuracy, offensiveness, opinions, reliability, privacy
                practices, or other policies of or contained in the Third-Party Websites
                or the Third-Party Content. Inclusion of, linking to, or permitting the
                use or installation of any Third-Party Websites or any Third-Party
                Content does not imply approval or endorsement thereof by us. If you
                decide to leave the Services and access the Third-Party Websites or to
                use or install any Third-Party Content, you do so at your own risk, and
                you should be aware these Legal Terms no longer govern. You should
                review the applicable terms and policies, including privacy and data
                gathering practices, of any website to which you navigate from the
                Services or relating to any applications you use or install from the
                Services. Any purchases you make through Third- Party Websites will be
                through other websites and from other companies, and we take no
                responsibility whatsoever in relation to such purchases which are
                exclusively between you and the applicable third party. You agree and
                acknowledge that we do not endorse the products or services offered on
                Third-Party Websites and you shall hold us blameless from any harm
                caused by your purchase of such products or services. Additionally, you
                shall hold us blameless from any losses sustained by you or harm caused
                to you relating to or resulting in any way from any Third-Party Content
                or any contact with Third-Party Websites.
            </p>
            <h2>12. ADVERTISERS</h2>
            <p>
                {" "}
                We allow advertisers to display their advertisements and other
                information in certain areas of the Services, such as sidebar
                advertisements or banner advertisements. We simply provide the space to
                place such advertisements, and we have no other relationship with
                advertisers.
            </p>
            <h2>13. SERVICES MANAGEMENT</h2>
            <p>
                {" "}
                We reserve the right, but not the obligation, to: (1) monitor the
                Services for violations of these Legal Terms; (2) take appropriate legal
                action against anyone who, in our sole discretion, violates the law or
                these Legal Terms, including without limitation, reporting such user to
                law enforcement authorities; (3) in our sole discretion and without
                limitation, refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion and
                without limitation, notice, or liability, to remove from the Services or
                otherwise disable all files and content that are excessive in size or
                are in any way burdensome to our systems; and (5) otherwise manage the
                Services in a manner designed to protect our rights and property and to
                facilitate the proper functioning of the Services.
            </p>
            <h2>14. PRIVACY POLICY</h2>
            <p>
                We care about data privacy and security. Please review our Privacy
                Policy: http://www.preclinique.co.uk/privacy-policy. By using the
                Services, you agree to be bound by our Privacy Policy, which is
                incorporated into these Legal Terms. Please be advised the Services are
                hosted in the United Kingdom. If you access the Services from any other
                region of the world with laws or other requirements governing personal
                data collection, use, or disclosure that differ from applicable laws in
                the United Kingdom, then through your continued use of the Services, you
                are transferring your data to the United Kingdom, and you expressly
                consent to have your data transferred to and processed in the United
                Kingdom.{" "}
            </p>
            <h2>15. TERM AND TERMINATION</h2>
            <p>
                These Legal Terms shall remain in full force and effect while you use
                the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
                WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
                REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
                OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
                ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or
                suspend your account for any reason, you are prohibited from registering
                and creating a new account under your name, a fake or borrowed name, or
                the name of any third party, even if you may be acting on behalf of the
                third party. In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including without
                limitation pursuing civil, criminal, and injunctive redress.{" "}
            </p>
            <h2>16. MODIFICATIONS AND INTERRUPTIONS</h2>
            <p>
                We reserve the right to change, modify, or remove the contents of the
                Services at any time or for any reason at our sole discretion without
                notice. However, we have no obligation to update any information on our
                Services. We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Services. We cannot guarantee the Services will be available at all
                times. We may experience hardware, software, or other problems or need
                to perform maintenance related to the Services, resulting in
                interruptions, delays, or errors. We reserve the right to change,
                revise, update, suspend, discontinue, or otherwise modify the Services
                at any time or for any reason without notice to you. You agree that we
                have no liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Services during any
                downtime or discontinuance of the Services. Nothing in these Legal Terms
                will be construed to obligate us to maintain and support the Services or
                to supply any corrections, updates, or releases in connection therewith.{" "}
            </p>
            <h2>17. GOVERNING LAW</h2>
            <p>
                These Legal Terms are governed by and interpreted following the laws of
                the United Kingdom, and the use of the United Nations Convention of
                Contracts for the International Sales of Goods is expressly excluded. If
                your habitual residence is in the EU, and you are a consumer, you
                additionally possess the protection provided to you by obligatory
                provisions of the law in your country to residence. Preclinique and
                yourself both agree to submit to the non-exclusive jurisdiction of the
                courts of Manchester, which means that you may make a claim to defend
                your consumer protection rights in regards to these Legal Terms in the
                United Kingdom, or in the EU country in which you reside.{" "}
            </p>
            <h2>18. DISPUTE RESOLUTION</h2>
            <p>
                {" "}
                nformal Negotiations To expedite resolution and control the cost of any
                dispute, controversy, or claim related to these Legal Terms (each a
                &#039;Dispute&#039; and collectively, the &#039;Disputes&#039;) brought
                by either you or us (individually, a &#039;Party&#039; and collectively,
                the &#039;Parties&#039;), the Parties agree to first attempt to
                negotiate any Dispute (except those Disputes expressly provided below)
                informally for at least thirty (30) days before initiating arbitration.
                Such informal negotiations commence upon written notice from one Party
                to the other Party. Binding Arbitration Any dispute arising from the
                relationships between the Parties to these Legal Terms shall be
                determined by one arbitrator who will be chosen in accordance with the
                Arbitration and Internal Rules of the European Court of Arbitration
                being part of the European Centre of Arbitration having its seat in
                Strasbourg, and which are in force at the time the application for
                arbitration is filed, and of which adoption of this clause constitutes
                acceptance. The seat of arbitration shall be Manchester, England. The
                language of the proceedings shall be English. Applicable rules of
                substantive law shall be the law of England. Restrictions The Parties
                agree that any arbitration shall be limited to the Dispute between the
                Parties individually. To the full extent permitted by law, (a) no
                arbitration shall be joined with any other proceeding; (b) there is no
                right or authority for any Dispute to be arbitrated on a class-action
                basis or to utilise class action procedures; and (c) there is no right
                or authority for any Dispute to be brought in a purported representative
                capacity on behalf of the general public or any other persons.
                Exceptions to Informal Negotiations and Arbitration The Parties agree
                that the following Disputes are not subject to the above provisions
                concerning informal negotiations binding arbitration: (a) any Disputes
                seeking to enforce or protect, or concerning the validity of, any of the
                intellectual property rights of a Party; (b) any Dispute related to, or
                arising from, allegations of theft, piracy, invasion of privacy, or
                unauthorised use; and (c) any claim for injunctive relief. If this
                provision is found to be illegal or unenforceable, then neither Party
                will elect to arbitrate any Dispute falling within that portion of this
                provision found to be illegal or unenforceable and such Dispute shall be
                decided by a court of competent jurisdiction within the courts listed
                for jurisdiction above, and the Parties agree to submit to the personal
                jurisdiction of that court.
            </p>

            <h2>19. CORRECTIONS</h2>
            <p>
                {" "}
                There may be information on the Services that contains typographical
                errors, inaccuracies, or omissions, including descriptions, pricing,
                availability, and various other information. We reserve the right to
                correct any errors, inaccuracies, or omissions and to change or update
                the information on the Services at any time, without prior notice.
            </p>
            <h2>20. DISCLAIMER</h2>
            <p>
                {" "}
                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
                THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&#039;
                CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
                THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
                FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS
                TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
                OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
                OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
                ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </p>
            <h2>21. LIMITATIONS OF LIABILITY</h2>
            <p>
                {" "}
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
                YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
                USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
                OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
                BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF
                ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                ADDITIONAL RIGHTS.
            </p>
            <h2>22. INDEMNIFICATION</h2>
            <p>
                You agree to defend, indemnify, and hold us harmless, including our
                subsidiaries, affiliates, and all of our respective officers, agents,
                partners, and employees, from and against any loss, damage, liability,
                claim, or demand, including reasonable attorneys&#039; fees and
                expenses, made by any third party due to or arising out of: (1) use of
                the Services; (2) breach of these Legal Terms; (3) any breach of your
                representations and warranties set forth in these Legal Terms; (4) your
                violation of the rights of a third party, including but not limited to
                intellectual property rights; or (5) any overt harmful act toward any
                other user of the Services with whom you connected via the Services.
                Notwithstanding the foregoing, we reserve the right, at your expense, to
                assume the exclusive defence and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your expense,
                with our defence of such claims. We will use reasonable efforts to
                notify you of any such claim, action, or proceeding which is subject to
                this indemnification upon becoming aware of it.{" "}
            </p>
            <h2>23. USER DATA</h2>
            <p>
                {" "}
                We will maintain certain data that you transmit to the Services for the
                purpose of managing the performance of the Services, as well as data
                relating to your use of the Services. Although we perform regular
                routine backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken using
                the Services. You agree that we shall have no liability to you for any
                loss or corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of such data.
            </p>
            <h2>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
            <p>
                Visiting the Services, sending us emails, and completing online forms
                constitute electronic communications. You consent to receive electronic
                communications, and you agree that all agreements, notices, disclosures,
                and other communications we provide to you electronically, via email and
                on the Services, satisfy any legal requirement that such communication
                be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
                US OR VIA THE SERVICES. You hereby waive any rights or requirements
                under any statutes, regulations, rules, ordinances, or other laws in any
                jurisdiction which require an original signature or delivery or
                retention of non-electronic records, or to payments or the granting of
                credits by any means other than electronic means.{" "}
            </p>
            <h2>25. MISCELLANEOUS</h2>
            <p>
                These Legal Terms and any policies or operating rules posted by us on
                the Services or in respect to the Services constitute the entire
                agreement and understanding between you and us. Our failure to exercise
                or enforce any right or provision of these Legal Terms shall not operate
                as a waiver of such right or provision. These Legal Terms operate to the
                fullest extent permissible by law. We may assign any or all of our
                rights and obligations to others at any time. We shall not be
                responsible or liable for any loss, damage, delay, or failure to act
                caused by any cause beyond our reasonable control. If any provision or
                part of a provision of these Legal Terms is determined to be unlawful,
                void, or unenforceable, that provision or part of the provision is
                deemed severable from these Legal Terms and does not affect the validity
                and enforceability of any remaining provisions. There is no joint
                venture, partnership, employment or agency relationship created between
                you and us as a result of these Legal Terms or use of the Services. You
                agree that these Legal Terms will not be construed against us by virtue
                of having drafted them. You hereby waive any and all defences you may
                have based on the electronic form of these Legal Terms and the lack of
                signing by the parties hereto to execute these Legal Terms.{" "}
            </p>
            <h2>26. CONTACT US</h2>
            <p>
                In order to resolve a complaint regarding the Services or to receive
                further information regarding use of the Services, please contact us at:{" "}
            </p>
            <p>Preclinique</p>
            <p>admin@preclinique.co.uk</p>
        </div>
    );
};

export default TermsAndConditions;