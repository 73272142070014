import Marquee from "react-marquee-slider";
import ICL from "../../assets/img/ICL.png";
import UOB from "../../assets/img/UoB.png";
import UON from "../../assets/img/UoN.svg";
import UOM from "../../assets/img/UoM.png";
import UCL from "../../assets/img/UCL.svg";
import React, { useState, useEffect } from "react";
import "./MarqueeFeatures.css";

const images = [ICL, UOB, UON, UOM, UCL];

const MarqueeFeatures = () => {
  const baseItemStyle = {
    width: "64px",
    margin: "0 50px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    marginBottom: "10px",
  };

  const numberOfItems = 20;
  let imageIndex = 0;

  const getNextImage = () => {
    const image = images[imageIndex];
    imageIndex = (imageIndex + 1) % images.length;

    let styleOverride = {};
    if (image === UOB) {
      styleOverride = { width: "180px" };
    } else if (image === UON) {
      styleOverride = { width: "110px" };
    } else if (image === UOM) {
      styleOverride = { width: "140px" };
    } else if (image === UCL) {
      styleOverride = { width: "120px" };
    }

    return { image, styleOverride };
  };

  const items = Array.from({ length: numberOfItems }).map(() => {
    const { image, styleOverride } = getNextImage();
    return (
      <div style={{ ...baseItemStyle, ...styleOverride }}>
        <img src={image} alt="Image" style={imageStyle} />
      </div>
    );
  });

  const [velocity, setVelocity] = useState(25);

  useEffect(() => {
    const updateVelocity = () => {
      const newVelocity = window.innerWidth < 768 ? 10 : 25; // Slower velocity for smaller screens
      setVelocity(newVelocity);
    };

    updateVelocity();
    window.addEventListener("resize", updateVelocity);

    return () => window.removeEventListener("resize", updateVelocity);
  }, []);

  return (
    <section className="feature" id="features">
      <h2 className="specific-university" style={{ textAlign: "center" }}>
        Coming to your University:
      </h2>
      <div style={{ overflow: "hidden", width: "100%" }}>
        <Marquee velocity={velocity}>{items}</Marquee>
      </div>
    </section>
  );
};

export default MarqueeFeatures;
