import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Paper,
  Grid,
  Box,
  Container,
  IconButton,
} from "@mui/material";
import {
  retrieveFromReview,
  getQuestionsByIds,
  removeFromReview,
} from "../../../../api/auth";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import { getAllMainCategoriesWithQuestionCount, getSubscriptionStatus } from "../../../../api/auth";
import { getSubcategoriesByMainCategoryWithQuestionCount } from "../../../../api/auth";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";

const DashboardReviewPage = () => {
    const navigate = useNavigate();
  const [reviewData, setReviewData] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [isQuestionClicked, setIsQuestionClicked] = useState(false);

  const [desiredOutcomes, setDesiredOutcomes] = useState(null);

  const universityName = useSelector((state) => state.auth.universityName);
  const courseName = useSelector((state) => state.auth.courseName);
    const userId = useSelector((state) => state.auth.userId);
    const [subscriptionStatus, setSubscriptionStatus] = useState("");





    useEffect(() => {
        async function fetchSubscriptionStatus() {
            try {
                const subscriptionResponse = await getSubscriptionStatus();
                const status = subscriptionResponse.status;
                setSubscriptionStatus(status);

                // Redirect based on subscription status
                if (status === 'active') {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to dashboard...');

                } else {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to payment...');
                    navigate('/dashboard/payment');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        }

        fetchSubscriptionStatus();
    }, []);




  useEffect(() => {
    async function fetchMainCategories() {
      if (universityName && courseName) {
        try {
          const response = await getAllMainCategoriesWithQuestionCount(
            universityName,
            courseName
          );
          setMainCategories(response.data.mainCategories);
        } catch (error) {
          console.error("Error fetching main categories:", error);
        }
      }
    }
    fetchMainCategories();
  }, [universityName, courseName]);

  useEffect(() => {
    if (selectedCategoryId) {
      const fetchSubcategories = async () => {
        try {
          const response =
            await getSubcategoriesByMainCategoryWithQuestionCount(
              selectedCategoryId,
              userId
            );
          setSubCategories((prev) => ({
            ...prev,
            [selectedCategoryId]: response.data.subcategories,
          }));
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };
      fetchSubcategories();
    }
  }, [selectedCategoryId, userId]);

  // Function to handle category change
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    setSelectedSubcategoryId(""); // Reset subcategory selection
  };

  // Function to handle subcategory change
  const handleSubcategoryChange = (event) => {
    setSelectedSubcategoryId(event.target.value);
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [detailedDialogOpen, setDetailedDialogOpen] = useState(false);

  const handleEntryClick = async (question) => {
    const questionIndex = reviewData.findIndex(
      (q) => q.question_id === question.question_id
    );
    if (questionIndex !== -1) {
      setCurrentQuestionIndex(questionIndex);
    }

    try {
      const questionIds = [question.question_id];

      const response = await getQuestionsByIds(questionIds, userId);

      const questionDetails = response.data.questions[0];

      setDesiredOutcomes({
        ...questionDetails,
        user_option_text: question.user_option_text,
      });

      setDetailedDialogOpen(true);
    } catch (error) {
      console.error("Error fetching question details:", error);
    }
    setIsQuestionClicked(true);
    setSelectedQuestion(question);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < reviewData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const fetchQuestionDetailsAtIndex = async (index) => {
    const question = reviewData[index];
    if (question) {
      handleEntryClick(question);
    }
  };

  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        // Fetch review data based on selected category and subcategory
        // If no category or subcategory is selected, fetch all review data
        const catId = selectedCategoryId || "";
        const subcatId = selectedSubcategoryId || "";
        const response = await retrieveFromReview(userId, catId, subcatId);

        if (response.data.success) {
          setReviewData(response.data.data);
        } else {
          console.error("Unable to fetch review data");
        }
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
    };

    fetchReviewData();
  }, [userId, selectedCategoryId, selectedSubcategoryId]);

  const handleGoToQuestion = async () => {
    if (selectedQuestion) {
      try {
        const questionIds = [selectedQuestion.question_id];
        const response = await getQuestionsByIds(questionIds);

        // Extract the desired information
        const questionDetails = response.data.questions[0];
        setDesiredOutcomes({
          ...questionDetails,
          user_option_text: selectedQuestion.user_option_text, // Add user_option_text to desiredOutcomes
        });

        // Open the detailed dialog

        setDetailedDialogOpen(true);
      } catch (error) {
        console.error("Error fetching question details:", error);
      }
    }
  };

  const handleRemoveFromList = async () => {
    if (selectedQuestion) {
      try {
        const response = await removeFromReview(
          userId,
          selectedQuestion.question_id
        );
        if (response.data.success) {
          // Remove the question from the local state
          setReviewData((prevData) =>
            prevData.filter(
              (entry) => entry.question_id !== selectedQuestion.question_id
            )
          );
          // Close any open dialogs
          setDetailedDialogOpen(false);
          // Clear the desired outcomes
          setDesiredOutcomes(null);
        } else {
          console.error("Unable to remove question from the list");
        }
      } catch (error) {
        console.error("Error removing question from the list:", error);
      }
    }
  };

  const handleClose = () => {
    setSelectedQuestion(null);
  };

  const handleDetailedDialogClose = () => {
    setDetailedDialogOpen(false);
    setDesiredOutcomes(null);
    setCurrentQuestionIndex(-1);
    setIsQuestionClicked(false); // Reset the flag
  };

  useEffect(() => {
    if (
      currentQuestionIndex >= 0 &&
      currentQuestionIndex < reviewData.length &&
      isQuestionClicked
    ) {
      const question = reviewData[currentQuestionIndex];
      if (question) {
        handleEntryClick(question);
      }
    }
  }, [currentQuestionIndex, reviewData, isQuestionClicked]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "rgb(249, 249, 249)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Review
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                padding: "10px",
                overflowY: "auto",
              }}
            >
              <FormControl fullWidth margin="normal">
                <InputLabel>Main Category</InputLabel>
                <Select
                  value={selectedCategoryId}
                  onChange={handleCategoryChange}
                  label="Main Category"
                >
                  {mainCategories.map((category) => (
                    <MenuItem key={category.cat_id} value={category.cat_id}>
                      {category.cat_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Subcategory Dropdown */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Subcategory</InputLabel>
                <Select
                  value={selectedSubcategoryId}
                  onChange={handleSubcategoryChange}
                  label="Subcategory"
                  disabled={!selectedCategoryId}
                >
                  {subCategories[selectedCategoryId]?.map((subcat) => (
                    <MenuItem key={subcat.subcat_id} value={subcat.subcat_id}>
                      {subcat.subcategory_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
            {/* Place other components here if needed */}
          </Grid>

          <Grid item xs={12} md={8}>
            {/* This is where your review questions will be displayed */}
            <Paper
              elevation={1}
              sx={{
                padding: "10px",
                height: "600px",
                overflowY: "auto",
              }}
            >
              {reviewData.length > 0 ? (
                <div>
                  {reviewData.map((entry, index) => (
                    <Paper
                      key={index}
                      onClick={() => handleEntryClick(entry)}
                      elevation={0}
                      square={true}
                      sx={{
                        minHeight: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        marginBottom: "10px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        ...(entry.correct
                          ? {
                              borderColor: "#4caf50",
                              backgroundColor: "#e8f5e9",
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                            }
                          : {
                              borderColor: "#ff5252",
                              backgroundColor: "#ffebee",
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                            }),
                        "&:hover": {
                          backgroundColor: entry.correct
                            ? "#c8e6c9"
                            : "#ffcdd2", // Slightly darker on hover
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                        },
                      }}
                    >
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {index + 1}. {entry.question_text}
                      </Typography>
                      {entry.correct ? (
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      ) : (
                        <HighlightOffIcon style={{ color: "red" }} />
                      )}
                    </Paper>
                  ))}
                </div>
              ) : (
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                  <Typography variant="h6">No Questions Available.</Typography>
                  <Typography variant="subtitle1">
                    Carry out some questions in this category.
                  </Typography>
                </Box>
              )}
              {/* Your mapped questions go here */}
            </Paper>
          </Grid>
        </Grid>

        {desiredOutcomes && (
          <Dialog
            open={detailedDialogOpen}
            onClose={handleDetailedDialogClose}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="black"
                  margin="8px"
                >
                  Question: {desiredOutcomes.question_text}
                </Typography>
                <IconButton onClick={handleDetailedDialogClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              {desiredOutcomes.Choices.map((choice, index) => (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    p: 2,
                    m: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    backgroundColor: choice.is_correct ? "#e8f5e9" : "#ffebee",
                    border: `1px solid ${
                      choice.is_correct ? "#4caf50" : "#ff5252"
                    }`,
                    ...(choice.option_text ===
                      desiredOutcomes.user_option_text && {
                      border: `2px dashed ${
                        choice.is_correct ? "#4caf50" : "#ff5252"
                      }`, // Dashed border color based on correctness
                    }),
                    "&:hover": {
                      backgroundColor: choice.is_correct
                        ? "#c8e6c9"
                        : "#ffcdd2",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    },
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <Typography variant="subtitle1">
                    Option {index + 1}:
                  </Typography>
                  <Typography variant="body1">{choice.option_text}</Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Explanation: {choice.explanation_text}
                  </Typography>
                  {choice.option_text === desiredOutcomes.user_option_text && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: choice.is_correct ? "#4caf50" : "#ff5252",
                        mt: 1,
                      }}
                    >
                      You Selected
                    </Typography>
                  )}
                </Paper>
              ))}

              <Button
                variant="contained"
                color="primary"
                onClick={handleRemoveFromList}
                sx={{ m: 1 }}
              >
                Remove
              </Button>
            </DialogContent>
            <Box display="flex" justifyContent="space-between" p={2}>
              {currentQuestionIndex > 0 && (
                <IconButton
                  onClick={handlePreviousQuestion}
                  aria-label="previous"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              {currentQuestionIndex < reviewData.length - 1 && (
                <IconButton onClick={handleNextQuestion} aria-label="next">
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </Box>
          </Dialog>
        )}
      </Container>
    </Box>
  );
};

export default DashboardReviewPage;
