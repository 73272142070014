import React from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function HeroSection({
  isFirstSection,
  columnDirection,
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  subHeadline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <>
      <div
        className={
          isFirstSection && !lightBg
            ? "home__hero-section isFirst darkBg"
            : isFirstSection
            ? "home__hero-section isFirst"
            : lightBg
            ? "home__hero-section"
            : "home__hero-section darkBg"
        }
      >
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: columnDirection
                ? "column"
                : imgStart === "start"
                ? "row-reverse"
                : "row",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <h1 className={lightText ? "heading" : "heading dark"}>
                  {headline.split("\n").map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </h1>

                {subHeadline && (
                  <h2
                    className={lightText ? "sub-heading" : "sub-heading dark"}
                  >
                    {subHeadline.split(" ").map((word, idx) => (
                      <React.Fragment key={idx}>
                        {word === "Your" ? (
                          <span className="highlight-your">{word}</span>
                        ) : word === "Our" ? (
                          <span className="highlight-our">{word}</span>
                        ) : (
                          word
                        )}{" "}
                      </React.Fragment>
                    ))}
                  </h2>
                )}

                <p
                  className={
                    lightTextDesc
                      ? `home__hero-subtitle ${isFirstSection ? "nowrap" : ""}`
                      : "home__hero-subtitle dark"
                  }
                >
                  {description.split("\n").map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
                <Link to="/register">
                  <Button buttonSize="btn--wide">{buttonLabel}</Button>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src={img} alt={alt} className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
