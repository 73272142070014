import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  getUserDetails,
  resetAllProgress,
  updatePassword,
  updateUserDetails,
  getSubId,
  cancelSubscription,
} from "../../../../api/auth";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DashboardSettings = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userData, setUserData] = useState({
    username: "",
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    universityName: "",
    classYear: "",
    graduationYear: "",
    isVerified: false,
    subscriptionId: "",
  });

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [openDialog, setOpenDialog] = useState(false);

  // const [subscriptionId, setSubscriptionId] = useState({})

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleConfirmCancellation = async () => {
    try {
      const response = await cancelSubscription(userData.subscriptionId);
      console.log("Subscription cancelled:", response);
      alert("Subscription cancelled successfully.");
      handleCloseDialog();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert("Failed to cancel subscription.");
    }
  };

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handlePasswordUpdate = async () => {
    // Validate passwords here (e.g., check if newPassword and confirmNewPassword match)
    // Call API to update the password
    try {
      const response = await updatePassword({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        confirmNewPassword: passwords.confirmNewPassword,
        userId, // If needed
      });
      alert("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password");
    }
  };

  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await getUserDetails();
        //console.log(response);
        //console.log("user", userId);
        const subId = await getSubId(userId);
        console.log("subid", subId);
        const subscriptionIdString = subId.subscriptionId;
        // console.log("sidlog", subscriptionIdlog);
        if (response.data) {
          console.log(response.data);
          setUserData((currentUserData) => ({
            ...currentUserData, // This now correctly references the current state at the time this update is applied.
            username: response.data.username,
            email: response.data.email,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            universityName: response.data.universityName,
            classYear: response.data.classYear,
            subscriptionId: subscriptionIdString,
            graduationYear: response.data.graduationYear,
            isVerified: response.data.isVerified,
          }));
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    fetchUserDetails();
  }, []);

  async function handleResetProgress() {
    try {
      // Assuming currentUser.id has the user's ID. Modify as per your implementation.
      const response = await resetAllProgress(userId);
      if (response.data.success) {
        console.log("Progress reset successfully");

        alert("Your progress has been reset successfully!");
      } else {
        console.error("Error resetting progress:", response.data.error);
        alert(
          "There was an error resetting your progress. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error calling resetAllProgress API:", error);
      alert(
        "There was an error resetting your progress. Please try again later."
      );
    }
  }

  const classYearOptions = [
    { value: "Year 1", label: "Year 1" },
    { value: "Year 2", label: "Year 2" },
    { value: "Year 3", label: "Year 3" },
    { value: "Year 4", label: "Year 4" },
    { value: "Year 5", label: "Year 5" },
    // Add more as needed
  ];

  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from({ length: 10 }, (_, i) => currentYear + i);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "rgb(249, 249, 249)", // Set the RGB color as the background
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <div>
          <h1 style={{ margin: "20px 0" }}>Dashboard Settings</h1>
          {/* Add other components or features specific to this page here */}
        </div>
        <Grid container spacing={2}>
          {/* First horizontal rectangle */}
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 240,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <div>
                <h2 style={{ marginBottom: "20px" }}>Edit Account Details</h2>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="filled"
                    value={userData.firstName}
                    onChange={(e) =>
                      setUserData({ ...userData, firstName: e.target.value })
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="filled"
                    value={userData.lastName}
                    onChange={(e) =>
                      setUserData({ ...userData, lastName: e.target.value })
                    }
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Username"
                    variant="filled"
                    value={userData.username}
                    onChange={(e) =>
                      setUserData({ ...userData, username: e.target.value })
                    }
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="filled"
                    value={userData.email}
                    disabled
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                    margin="normal"
                    InputProps={{
                      disableUnderline: true, // hides the default underline
                      sx: {
                        "& .Mui-disabled": {
                          color: "grey", // changes the text color
                        },
                        "&:before": {
                          // styles the underline part
                          borderBottom: "1px solid black",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          // ensure the underline only appears for non-disabled state
                          borderBottom: "1px solid black",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="University"
                    variant="filled"
                    value={userData.universityName}
                    disabled
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        universityName: e.target.value,
                      })
                    }
                    margin="normal"
                    InputProps={{
                      disableUnderline: true, // hides the default underline
                      sx: {
                        "& .Mui-disabled": {
                          color: "grey", // changes the text color
                        },
                        "&:before": {
                          // styles the underline part
                          borderBottom: "1px solid black",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          // ensure the underline only appears for non-disabled state
                          borderBottom: "1px solid black",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="isVerfied"
                    variant="filled"
                    value={userData.isVerified}
                    disabled
                    onChange={(e) =>
                      setUserData({ ...userData, isVerified: e.target.value })
                    }
                    margin="normal"
                    InputProps={{
                      disableUnderline: true, // hides the default underline
                      sx: {
                        "& .Mui-disabled": {
                          color: "grey", // changes the text color
                        },
                        "&:before": {
                          // styles the underline part
                          borderBottom: "1px solid black",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          // ensure the underline only appears for non-disabled state
                          borderBottom: "1px solid black",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" variant="filled">
                    <InputLabel>Class Year</InputLabel>
                    <Select
                      value={userData.classYear || ""}
                      onChange={(e) =>
                        setUserData({ ...userData, classYear: e.target.value })
                      }
                      label="Class Year"
                    >
                      {classYearOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" variant="filled">
                    <InputLabel>Graduation Year</InputLabel>
                    <Select
                      value={userData.graduationYear || ""}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          graduationYear: e.target.value,
                        })
                      }
                      label="Graduation Year"
                    >
                      {yearsRange.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}></Grid>
              </Grid> */}

              {/* Include other fields as necessary */}
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={async () => {
                  try {
                    const response = await updateUserDetails({
                      first_name: userData.firstName, // Changed from userData.firstname
                      last_name: userData.lastName, // Changed from userData.lastname
                      user_name: userData.username,
                      class_year: userData.classYear,
                      graduation_year: userData.graduationYear,
                      user_id: userId,
                      // ...include other fields you want to update
                    });
                    alert(response.data.message); // Display a success message
                  } catch (error) {
                    console.error("Error updating user details:", error);
                    alert(error.response.data.error); // Display an error message
                  }
                }}
              >
                Update Profile
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={0} // Remove default elevation shadow
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 240, // Adjust the height as needed
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)", // Custom shadow
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2 style={{ marginBottom: "20px" }}>Update Password</h2>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Current Password"
                    type="password"
                    name="currentPassword"
                    variant="filled"
                    value={passwords.currentPassword}
                    onChange={handlePasswordChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    name="newPassword"
                    variant="filled"
                    value={passwords.newPassword}
                    onChange={handlePasswordChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    type="password"
                    name="confirmNewPassword"
                    variant="filled"
                    value={passwords.confirmNewPassword}
                    onChange={handlePasswordChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handlePasswordUpdate}
              >
                Update Password
              </Button>
              {/* Content for the third rectangle */}
            </Paper>
          </Grid>

          {/* Second horizontal rectangle */}
          <Grid item xs={12}>
            <Paper
              elevation={0} // Remove default elevation shadow
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                // minHeight: 240, // Adjust the height as needed
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)", // Custom shadow
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <div>
                <h2 style={{ marginBottom: "20px" }}>Progression</h2>
              </div>

              <div className="reset-button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleResetProgress}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "gray", // Change this as needed for the hover state
                    },
                  }}
                  // Optional: Use this to prevent all caps, which is default for MUI buttons
                >
                  Reset All Progress
                </Button>
              </div>

              {/* Content for the second rectangle */}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 160,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <h2>Subscription Options</h2>
              <div
                className="reset-button-container"
                style={{ paddingTop: "15px" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenDialog}
                >
                  Cancel Subscription
                </Button>
              </div>
            </Paper>
          </Grid>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Cancel Subscription"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel your subscription? This action
                cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                No
              </Button>
              <Button
                onClick={handleConfirmCancellation}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Third horizontal rectangle, on its own line */}
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardSettings;
