import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getUserAverageScore,
  getAllUserScores,
  getAllScoresForPercentile,
  getScoresFromYear,
  getScoresFromCourse,
} from "../../../../api/auth";

import jStat from "jstat";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const DashboardAnalyticsPage = () => {
  const userId = useSelector((state) => state.auth.userId);
  const university_name = useSelector((state) => state.auth.universityName);
  const course = useSelector((state) => state.auth.courseName);
  const class_year = useSelector((state) => state.auth.classYear);

  const [averageScore, setAverageScore] = useState(0);
  const [allUsersAverageScore, setAllUsersAverageScore] = useState(0);
  const [curveData, setCurveData] = useState([]);
  const [averageScoreFromYear, setAverageScoreFromYear] = useState(0);
  const [averageScoreFromCourse, setAverageScoreFromCourse] = useState(0);
  const [closestPercentile, setClosestPercentile] = useState(null);
  const [hoveredValue, setHoveredValue] = useState(null);

  async function usrAvgScore() {
    try {
      const response = await getUserAverageScore(userId);
      const averageScore =
        parseFloat(response?.data?.scores?.[0]?.average_score) || 0;

      setAverageScore(averageScore);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  useEffect(() => {
    usrAvgScore();
    fetchAllUsersAverageScore();
  }, [userId]);

  useEffect(() => {
    async function fetchAllScoresForPercentile() {
      try {
        const response = await getAllScoresForPercentile();
        const scores = response.data.scores;

        // Calculate mean and standard deviation
        const mean = jStat.mean(scores);
        const stdDev = jStat.stdev(scores);

        // Calculate percentiles and scores
        let curveData = [];
        for (let i = 1; i <= 100; i++) {
          let scoreAtPercentile = jStat.normal.inv(i / 100, mean, stdDev); // Inverse of the cumulative distribution function

          // Clamp the score to [0, 100]
          scoreAtPercentile = Math.min(100, Math.max(0, scoreAtPercentile));

          curveData.push({
            x: `Percentile: ${i}`,
            y: scoreAtPercentile.toFixed(2), // Round to two decimal places
          });
        }

        // Calculate the closest percentile to the user's average score
        const closestPercentile = curveData.reduce((closest, entry) => {
          const currentDiff = Math.abs(parseFloat(entry.y) - averageScore);
          const closestDiff = Math.abs(parseFloat(closest.y) - averageScore);
          return currentDiff < closestDiff ? entry : closest;
        }, curveData[0]);

        setCurveData(curveData);
        setClosestPercentile(closestPercentile);
      } catch (error) {
        console.error(error);
      }
    }

    fetchAllScoresForPercentile();
  }, [averageScore]);

  async function fetchAllUsersAverageScore() {
    try {
      const response = await getAllUserScores();
      const average =
        parseFloat(response?.data?.scores?.[0]?.average_score) || 0;
      setAllUsersAverageScore(average);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchAverageScoreFromYear() {
      try {
        const response = await getScoresFromYear(class_year);

        if (
          response.data.success &&
          response.data.scores &&
          response.data.scores[0]?.average_score
        ) {
          setAverageScoreFromYear(
            parseFloat(response.data.scores[0].average_score)
          );
        } else {
          console.error(
            "Invalid response structure from getScoresFromYear:",
            response
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchAverageScoreFromYear();
  }, [class_year]);

  useEffect(() => {
    async function fetchAverageScoreFromCourse() {
      try {
        const response = await getScoresFromCourse(course, university_name);

        if (
          response.data.success &&
          response.data.scores &&
          response.data.scores[0]?.average_score
        ) {
          setAverageScoreFromCourse(
            parseFloat(response.data.scores[0].average_score)
          );
        } else {
          console.error(
            "Invalid response structure from getScoresFromCourse:",
            response
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchAverageScoreFromCourse();
  }, [course, university_name]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "rgb(249, 249, 249)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <div>
          <Typography variant="h4" style={{ margin: "20px 0" }}>
            Analytics Page
          </Typography>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={6}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 480,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginBottom: "20px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Your Score VS Average Preclinique User
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={[
                    {
                      name: "User Score",
                      userScore: averageScore,
                      allUsersAverage: allUsersAverageScore,
                    },
                  ]}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="userScore" fill="#8884d8" />
                  <Bar dataKey="allUsersAverage" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 480,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                Score Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={333}>
                <BarChart
                  width={500}
                  height={333}
                  data={curveData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="x"
                    label={{
                      value: "Percentile",
                      position: "insideBottom",
                      dy: 10,
                    }}
                    tick={{ display: "none" }}
                  />
                  <YAxis
                    dataKey="y"
                    label={{
                      value: "Scores",
                      angle: -90,
                      position: "insideLeft",
                      dx: -10,
                    }}
                  />
                  <Tooltip
                    formatter={(value, name) => [
                      `Score: ${parseFloat(value).toFixed(2)}%`,
                    ]}
                  />
                  <Bar
                    dataKey="y"
                    isAnimationActive={false}
                    name="Your Percentile"
                    fill="red"
                  >
                    {curveData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.x === Math.round(averageScore) ||
                          entry.x === closestPercentile?.x
                            ? "red"
                            : "#8884d8"
                        }
                        onMouseOver={() => setHoveredValue(entry)}
                        onMouseOut={() => setHoveredValue(null)}
                      />
                    ))}
                  </Bar>
                  <Legend
                    iconSize={20}
                    wrapperStyle={{
                      color: "black",
                      paddingTop: "10px", // Add padding at the bottom of the legend
                      paddingLeft: "60px",
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 480,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                Scores from Year
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={[
                    {
                      name: "User Average Score",
                      userScore: averageScore,
                      source: "user",
                    },
                    {
                      name: "Average Score from Year",
                      userScore: averageScoreFromYear,
                      source: "year",
                    },
                  ]}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="userScore" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                minHeight: 480,
                boxShadow: "0 6px 20px rgba(56, 125, 255, 0.05)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                Scores from Course
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={[
                    {
                      name: "User Average Score",
                      userScore: averageScore,
                      source: "user",
                    },
                    {
                      name: "Average Score from Course",
                      userScore: averageScoreFromCourse,
                      source: "course",
                    },
                  ]}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="userScore" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardAnalyticsPage;
