import { createSlice } from "@reduxjs/toolkit";

/**create reducer */
export const questionReducer = createSlice({
  name: "questions",
  initialState: {
    queue: [], // This will store an array of question objects.
    answers: [],
    trace: 0,
    isSubmitted: [],
      isCorrect: [],
      type: 1,
      gettingResultFromResume: false,
  },
  reducers: {
    startExamAction: (state, action) => {
      const { question, answers } = action.payload; // Directly take the questions array from the payload
      return {
        ...state,
        queue: question,
          answers: answers,
          type: question[0]?.type || 1 // Use first question's type as a default 
      };
    },
    moveNextAction: (state) => {
      return {
        ...state,
        trace: state.trace + 1,
      };
    },
    movePrevAction: (state) => {
      return {
        ...state,
        trace: state.trace - 1,
      };
    },
    resetAllAction: () => {
      return {
        queue: [],
        trace: 0,
      };
    },
    setIsSubmitted: (state, action) => {
      const { index, value } = action.payload;
      state.isSubmitted[index] = value;
    },

    setIsCorrect: (state, action) => {
      const { index, value } = action.payload;
      state.isCorrect[index] = value;
    },
    setTraceAction: (state, action) => {
      state.trace = action.payload;
    },

    setTraceToZero: (state) => {
      state.trace = 0;
      },

    setQuestionGetFromResumeToFalse: (state) => {
          state.gettingResultFromResume = false;
    },

    setQuestionGetFromResumeToTrue: (state) => {
          state.gettingResultFromResume = true;
    },

    setType: (state, action) => { // Add a new reducer to set the 'type' value
          state.type = action.payload;
    },
  },
});

export const {
  startExamAction,
  moveNextAction,
  movePrevAction,
  resetAllAction,
  setIsSubmitted,
  setIsCorrect,
  setTraceAction,
    setTraceToZero,
    setType,
    setQuestionGetFromResumeToFalse,
    setQuestionGetFromResumeToTrue,
} = questionReducer.actions;

export default questionReducer.reducer;
