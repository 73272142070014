import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainCategoryList from "./DashboardQuestionBankComponents/MainCategoriesList";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {
  getAllMainCategoriesWithQuestionCount,
    getQuestionsToExclude,
    getSubscriptionStatus,
} from "../../../../../api/auth";
import {
  setQuestionGetFromResumeToFalse,
  setSelectedQuestionTypes,
  setQuestionExclusions,
} from "../../../../../redux/slices/questionTypesReducer";
import FilterQuestionsIcon from "../../../../../assets/img/filterQuestions.svg";
import { useNavigate } from "react-router-dom";

const DashboardQuestionBank = () => {
    const navigate = useNavigate();
    const [categories, setMainCategories] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [includeLongFormat, setIncludeLongFormat] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const didIComeFromResume = useSelector(
    (state) => state.questions.gettingResultFromResume
  );
  const questionTypes = useSelector((state) => state.type.value);

  const universityName = useSelector((state) => state.auth.universityName);
    const courseName = useSelector((state) => state.auth.courseName);




    useEffect(() => {
        async function fetchSubscriptionStatus() {
            try {
                const subscriptionResponse = await getSubscriptionStatus();
                const status = subscriptionResponse.status;
                setSubscriptionStatus(status);

                // Redirect based on subscription status
                if (status === 'active') {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to dashboard...');
                } else {
                    console.log('Subscription Status:', status);
                    console.log('Redirecting to payment...');
                    navigate('/dashboard/payment');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        }

        fetchSubscriptionStatus();
    }, []);




  useEffect(() => {
    async function fetchCategories() {
      try {
        // Combine logic for fetching categories, including universityName and courseName
        let response;
        if (universityName && courseName) {
          response = await getAllMainCategoriesWithQuestionCount(
            universityName,
            courseName
          );
        } else {
          response = await getAllMainCategoriesWithQuestionCount();
        }
        setMainCategories(response.data.mainCategories);

        // Furqan's logic for question exclusions
        const questionExclusions = await getQuestionsToExclude(userId);
        const questionIds = questionExclusions.data.scores.map(
          (score) => score.question_id
        );
        dispatch(setQuestionExclusions(questionIds));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching main categories:", error);
        setLoading(false);
      }
    }

    fetchCategories();
  }, [universityName, courseName]);

  useEffect(() => {
    setIncludeLongFormat(questionTypes.includes(2));
  }, [questionTypes]);

  const handleCheckboxChange = () => {
    setIncludeLongFormat(!includeLongFormat);
    if (!includeLongFormat) {
      dispatch(setSelectedQuestionTypes([1, 2]));
    } else {
      dispatch(setSelectedQuestionTypes([1]));
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Box
      // component="main"
      // sx={{
      //   backgroundColor: (theme) =>
      //     theme.palette.mode === "light"
      //       ? theme.palette.grey[100]
      //       : theme.palette.grey[900],
      //   flexGrow: 1,
      //   height: "100vh",
      //   overflow: "auto",
      //   p: 2,

      component="main"
      sx={{
        backgroundColor: "rgb(249, 249, 249)", // Set the RGB color as the background
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container maxWidth="1600px" sx={{ mt: 4, mb: 4 }}>
        <div>
          <h1 style={{ margin: "20px 0" }}>Question Bank</h1>
        </div>
        <Grid container spacing={2}>
          {/* First box (half of the top) */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              elevation={1} // Adjust the number to increase or decrease shadow depth
              square={false} // If set to true, it removes the border-radiu
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MainCategoryList mainCategories={categories} />
            </Paper>
          </Grid>

          {/* Second box (other half of the top) */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              elevation={1}
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 364.88,
              }}
            >
              {" "}
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Filter By Question
              </h2>
              <h3 style={{ textAlign: "center" }}>
                <Checkbox
                  checked={includeLongFormat}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "Include long format questions" }}
                />
                Include VSAQs
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src={FilterQuestionsIcon}
                  alt="Flagged Questions"
                  style={{ width: "250px", height: "250px" }}
                />
              </div>
            </Paper>
          </Grid>

          {/* Long rectangle at the bottom */}
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardQuestionBank;
