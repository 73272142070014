import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionsBySubcategories, getQuestionsByIds } from "../api/auth";
import * as Action from "../redux/reducers/question_reducer";

/**fetch question hook to fetch api data and set value to store */
export const useFetchQuestionBySubcatIds = (
    subcategoryIds,
    types = [1],
    excludedQuestionIds = []
) => {
    const dispatch = useDispatch();
    const [getData, setGetData] = useState({
        isLoading: false,
        apiData: [],
        serverError: null,
    });

    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            async function fetchData() {
                hasFetched.current = true;
                setGetData((prev) => ({ ...prev, isLoading: true }));
                try {
                    const responses = await Promise.all(
                        types.map((type) =>
                            getQuestionsBySubcategories(
                                subcategoryIds,
                                type,
                                excludedQuestionIds
                            )
                        )
                    );

                    const questionsFromDB = responses
                        .flatMap((res) => res.data.questions) // Flatten responses
                        .filter(
                            (question, index, self) =>
                                self.findIndex(
                                    (q) => q.question_id === question.question_id
                                ) === index
                        ); // Filter duplicates

                    // Shuffle questions here
                    const shuffledQuestions = shuffleArray(questionsFromDB);

                    // Structuring the data
                    const structuredData = shuffledQuestions.map((q) => ({
                        id: q.question_id,
                        question: q.question_text,
                        options: shuffleArray(q.Choices),
                        type: q.type,
                        lectureReference: q.lecture_reference,
                    }));

                    const answers = shuffledQuestions.map((q) => {
                        let correctOption = q.Choices.find((choice) => choice.is_correct);
                        return {
                            question_id: q.question_id,
                            correct_option_id: correctOption.option_id,
                        };
                    });

                    if (structuredData.length > 0) {
                        setGetData((prev) => ({
                            ...prev,
                            isLoading: false,
                            apiData: { question: structuredData, answers },
                        }));
                        dispatch(
                            Action.startExamAction({ question: structuredData, answers })
                        );
                    } else {
                        throw new Error("No Question Available");
                    }
                } catch (error) {
                    setGetData((prev) => ({
                        ...prev,
                        isLoading: false,
                        serverError: error.response
                            ? error.response.data.error
                            : error.message,
                    }));
                }
            }

            fetchData();
        }
    }, [dispatch, subcategoryIds, types, excludedQuestionIds]);
    return [getData, setGetData];
};

export const useFetchQuestionsByIds = (questionIds) => {
    const dispatch = useDispatch();
    const [getData, setGetData] = useState({
        isLoading: false,
        apiData: [],
        serverError: null,
    });

    useEffect(() => {
        setGetData((prev) => ({ ...prev, isLoading: true }));

        /** async function fetch backend data */
        (async () => {
            try {
                const response = await getQuestionsByIds(questionIds);
                const questionsFromDB = response.data.questions;

                let structuredData = questionsFromDB.map((q) => ({
                    id: q.question_id,
                    type: q.type,
                    question: q.question_text,
                    options: q.Choices,
                    lectureReference: q.lecture_reference,
                }));

                // Extracting correct answers
                let answers = questionsFromDB.map((q) => {
                    let correctOption = q.Choices.find((choice) => choice.is_correct);
                    return {
                        question_id: q.question_id,
                        correct_option_id: correctOption.option_id,
                    };
                });

                if (structuredData.length > 0) {
                    setGetData((prev) => ({ ...prev, isLoading: false }));
                    setGetData((prev) => ({
                        ...prev,
                        apiData: { question: structuredData, answers },
                    }));
                    const questionType = structuredData[0].type;

                    /**dispatch an action */
                    dispatch(
                        Action.startExamAction({
                            question: structuredData,
                            answers,
                            type: questionType,
                        })
                    );
                } else {
                    throw new Error("No Question Available");
                }
            } catch (error) {
                setGetData((prev) => ({ ...prev, isLoading: false }));
                setGetData((prev) => ({
                    ...prev,
                    serverError: error.response
                        ? error.response.data.error
                        : error.message,
                }));
            }
        })();
    }, [dispatch, questionIds]);

    return [getData, setGetData];
};

function shuffleArray(array) {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
}

/** MoveAction Dispatch function */
export const MoveNextQuestion = () => async (dispatch) => {
    try {
        dispatch(Action.moveNextAction()); /** increase trace by 1 */
    } catch (error) {
        console.log(error);
    }
};

/** PrevAction Dispatch function */
export const MovePrevQuestion = () => async (dispatch) => {
    try {
        dispatch(Action.movePrevAction()); /** decrease trace by 1 */
    } catch (error) {
        console.log(error);
    }
};