// ConfirmationCard.js

import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";

function ConfirmationCard({ selectedCategories, onConfirm, onCancel }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "20px",
        border: "1px solid #ddd", // added a border for clarity

        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // subtle shadow for a lifted appearance
      }}
    >
      {/* Title */}
      <div>
        <h2 style={{ borderBottom: "2px solid grey" }}>Confirm Selection</h2>
        <h3
          style={{
            color: "#333",
            fontWeight: "normal",
            lineHeight: 1.5,
            marginTop: "10px",
          }}
        >
          Please click 'Submit' after answering each question, and once you have
          completed all the questions, click the 'Finish' button. Finally,
          ensure to click 'Save Progress' to securely save all your progress.
        </h3>
      </div>

      {/* Categories Grouping */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexGrow: 1,
          overflowX: "auto", // ensure horizontal scroll if content exceeds the card's width
        }}
      >
        {selectedCategories.map((categoryGroup, index) => (
          <div
            key={index}
            style={{
              flexBasis: "0",
              flexGrow: "1",

              whiteSpace: "nowrap", // text will not wrap
            }}
          >
            <h3>Main Category: {categoryGroup.main}</h3>
            <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
              {categoryGroup.subs.map((subCatName, subIndex) => (
                <li key={subIndex} style={{ margin: "8px 0" }}>
                  Sub Category: {subCatName}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <Typography variant="body2" style={{ marginTop: "10px" }}>
        Note: If you wish to redo the questions you have already carried out,
        please reset your progress in the settings page.
      </Typography>

      {/* Confirm and Cancel buttons */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          marginLeft: "-7px",
        }}
      >
        <Button onClick={onConfirm}>Confirm</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
}
export default ConfirmationCard;
