import React from "react";
import "./Footer.css";

import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
  FaTiktok,
} from "react-icons/fa";
import { FaEarlybirds } from "react-icons/fa";
import { ReactComponent as Logo } from "../../../assets/img/realOwlLogo.svg";

function Footer() {
  return (
    <div className="footer-container">
      {/* <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Join our newsletter to receive the latest medical news!
        </p>
        <p className="footer-subscription-text">
          You can unsubscribe at any time.
        </p>
        <div className="input-areas">
          <form className="subscribe-input">
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <button className="subscribe-button">Subscribe</button>
          </form>{" "}
          DONT PUT THIS form one
          <div id="mc_embed_signup">
            <form
              action="https://preclinique.us12.list-manage.com/subscribe/post?u=8b34bf0bb5a93e344fa13257f&amp;id=5abf9d2256&amp;f_id=00cd44e0f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe</h2>

                <div className="mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    className="footer-input"
                    id="mce-EMAIL"
                    placeholder="Your Email"
                    required=""
                  />
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="text"
                    name="b_8b34bf0bb5a93e344fa13257f_5abf9d2256"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div className="clear">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="subscribe-button"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section> */}
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>

            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a
              href="https://www.instagram.com/preclinique/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              Instagram
            </a>

            <a
              href="https://chat.whatsapp.com/E2bWxEw8TVKED5oiI4V88P"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Whatsapp"
            >
              Whatsapp
            </a>

            <a
              href="https://www.tiktok.com/@preclinique"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              TikTok
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <Logo className="navbar-icon" />
              Preclinique
            </Link>
          </div>
          <small className="website-rights">Preclinique © 2023</small>
          <div className="social-icons">
            <a
              className="social-icon-link"
              href="https://www.instagram.com/preclinique/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              className="social-icon-link"
              href="https://chat.whatsapp.com/E2bWxEw8TVKED5oiI4V88P"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Whatsapp"
            >
              <FaWhatsapp />
            </a>

            <a
              className="social-icon-link"
              href="https://www.tiktok.com/@preclinique"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
