import React from "react";
import HeroSection from "../../UIElements/HeroSection";
import { homeObjOne, homeObjThree } from "./Data";
import Pricing from "../../UIElements/Pricing";
import MarqueeFeatures from "../../UIElements/MarqueeFeatures";
import CardSection from "../../UIElements/CardSection";
import DashboardSection from "../../UIElements/DashboardSection";

import WhoAreWeSection from "../../UIElements/WhoAreWeSection";
import TestimonialSection from "../../UIElements/TestmonialSection";
import Footer from "../Footer/Footer";
import CookieManager from "../../UIElements/CookieManager";

function Home() {
  return (
    <>
      <HeroSection {...homeObjOne} />
      <MarqueeFeatures />
      <DashboardSection />
      <CardSection />
      <WhoAreWeSection />
      <TestimonialSection />
      <Pricing />

      {/* <Pricing /> */}
    </>
  );
}

export default Home;
