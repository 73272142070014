import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import Lottie from "lottie-react";
import myLottieAnimation from "../../../assets/lottieAnimations/handWaving.json";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_eczdri7",
        "template_2bn4ypb",
        form.current,
        "2wcWBffpY3bQ6ltxe"
      )
      .then(
        (result) => {
          console.log(result.text);
          // You can add a notification for success here
        },
        (error) => {
          console.log(error.text);
          // You can add a notification for error here
        }
      );

    e.target.reset();
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Get in touch</h2>
      <span className="section__subtitle">Contact Us</span>

      <div className="contact__container container grid">
        {/* Other contact options */}
        <div className="contact__content">
          <Lottie
            animationData={myLottieAnimation}
            style={{ width: 35, height: 35 }}
            className="lottie-animation"
          />

          <div className="contact__info">
            {/* Email Contact Card */}
            <div className="contact__card">
              <FaEnvelope className="contact__card-icon" />
              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">
                admin@preclinique.co.uk
              </span>

              <a
                href="mailto:admin@preclinique.co.uk"
                className="contact__button"
              >
                Email us
              </a>
            </div>

            {/* <FaEnvelope className="contact__button-icon" /> */}

            <div className="contact__card">
              <FaWhatsapp className="contact__card-icon" />

              <h3 className="contact__card-title">Whatsapp</h3>
              <span className="contact__card-data">Student's Group Chat</span>

              <a
                href="https://chat.whatsapp.com/E2bWxEw8TVKED5oiI4V88P"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Whatsapp"
                className="contact__button"
              >
                Click here to join!
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact__content">
          <h3 className="contact__title">Write your message here!</h3>

          <form ref={form} onSubmit={sendEmail} className="contact__form">
            {/* Name Input */}
            <div className="contact__form-div">
              <label className="contact__form-tag">Name</label>
              <input
                type="text"
                name="from_name"
                className="contact__form-input"
                placeholder="Insert your name"
                required
              />
            </div>

            {/* Email Input */}
            <div className="contact__form-div">
              <label className="contact__form-tag">Mail</label>
              <input
                type="email"
                name="from_email"
                className="contact__form-input"
                placeholder="Insert your email"
                required
              />
            </div>

            {/* Message Input */}
            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Message</label>
              <textarea
                name="message"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Write your message"
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <button className="button button--flex">
              Send Message <FaEnvelope className="button__icon" />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
