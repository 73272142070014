import { FaEarlybirds } from "react-icons/fa";
import { ReactComponent as Logo } from "../../../assets/img/realOwlLogo.svg";
import React, { useState } from "react";
import { resetPassword } from "../../../api/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./ResetPassword.css";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";

const ResetPassword = () => {
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (values.password !== values.confirmPassword) {
      return setError("Passwords do not match");
    }

    const requestData = {
      token: token,
      newPassword: values.password,
      confirmNewPassword: values.confirmPassword,
    };

    try {
      await resetPassword(requestData);

      toast.success("🦉 Password reset successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);

      toast.error(`🦉 Error: ${error.response.data.errors[0].msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={onSubmit}>
        <h2 className="reset-brand-title">
          <Link to="/" className="brand-content">
                      <Logo className="brand-logo" />
                      <span className="brand-name">
                          Preclinique
                      </span>
          </Link>
        </h2>

        <div className="reset-input-group">
          <label htmlFor="password">New Password</label>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            variant="filled"
            id="password"
            name="password"
            value={values.password}
            onChange={onChange}
            required
            margin="normal"
          />
        </div>

        <div className="reset-input-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            variant="filled"
            id="confirmPassword"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={onChange}
            required
            margin="normal"
          />
        </div>

        {error && <p style={{ color: "red", margin: "10px 0" }}>{error}</p>}

        <button className="submit-button">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
